import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from '../../IQDatatable';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import { NIVEL_LOTE, NIVEL_FINCA, NIVELES } from '../../../../../sigat/constants';
import Actions from '../shared/CreateActions';
import DatatableMenu from '../shared/DatatableMenu';
import { list as listSuperficies} from "../../../../crud/superficie.crud";
import { TextField } from "@material-ui/core";
import { list, update, remove, create } from "../../../../crud/superficie.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio,
} from "@material-ui/core";

import LoteDialog from './loteDialog'
import reducer,  * as ducks from './createReducer';
import ConfigHeader from '../ConfigHeader'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const New = props => {

  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
  const [superficies, setSuperficies] = useState([]);
  const [supSelected, setSupSelected] = useState(null);
   const configSetup = useSelector(state => state.configuration);

  const fetchSuperficies = async () => {
    try {
      const response = await listSuperficies(configSetup.client.technical_name);
      setSuperficies(response.data)
      setSupSelected((response.data && response.data.length> 0 && response.data[0].id) || null)
    } catch (err) {
      console.error(err)
    } finally {}
  };

  const cbGetLoteData = (data) => {
    dispatch({type: ducks.ADD_SUP, payload: data});
  }

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchSuperficies();
    }
  }, [configSetup.client]);

  const nivelesJSX = Object.keys(NIVELES).map((key) => {
    return (<MenuItem value={key}>{NIVELES[key]}</MenuItem>)
  })

  const columns = [
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      name: 'Hectáreas',
      selector: 'hectarea',
      sortable: true,
    },
  ];

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/superficies'}
                name={'Superficies'}
                stage={'Nuevo'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Nuevo
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            initialValues={{
              nombre: "",
              activo: true,
              nivel: NIVEL_FINCA,
              hectarea: 0,
              lotes: state.children,
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 

              if (!values.nivel) {
                errors.nivel = 'Seleccione un nivel';
              } 

              if (!values.hectarea) {
                errors.hectarea = 'Ingrese el número de hectáreas';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                create(configSetup.client.technical_name,
                       {
                         nombre: values.nombre,
                         activo: values.activo,
                         lotes: state.children,
                         nivel: values.nivel,
                         hectarea: values.hectarea,
                       })
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/superficies/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <FormControl className={classes.formControl}>
                    <TextField
                      type="number"
                      label="Hectáreas"
                      margin="normal"
                      className="kt-width-full"
                      name="hectarea"
                      value={values.hectarea}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div className="form-group">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label">Nivel</InputLabel>
                        <Select
                          disabled
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.nivel}
                          name="nivel"
                          onChange={handleChange}
                        >
                          {nivelesJSX}
                        </Select>
                      </FormControl>
                </div>
                <div className="form-group">
                  <LoteDialog
                    padre={{ nombre: values.nombre }}
                    createDialog
                    cb={cbGetLoteData}
                  />
                </div>

                <div className="form-group">
                  {state.children !== null && (
                <DataTable
                  title="Lotes"
                  columns={columns}
                  data={state.children || []}
                />
              )}
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/superficies/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(New);
