import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from '../../IQDatatable';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import { NIVEL_LOTE, NIVEL_FINCA, NIVELES } from '../../../../../sigat/constants';
import Actions from '../shared/CreateActions';
import { TextField } from "@material-ui/core";
import { get, list, update, remove, create } from "../../../../crud/superficie.crud";
import { list as listSuperficies} from "../../../../crud/superficie.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio
} from "@material-ui/core";

import LoteDialog from './loteDialog'
import ConfigHeader from '../ConfigHeader'
import reducer,  * as ducks from './reducer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const Edit = props => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [initialForm, setInitialForm] = useState({nombre: '', hectarea: 0, nivel: null, activo: null, lotes: []})
  const [lotes, setLotes] = useState([])
  const [loteSelected, setLoteSelected] = useState(null);

  useEffect(() => {
    setLoteSelected((lotes.length > 9 && lotes[0]) || null);
  }, [lotes]);
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await get(configSetup.client.technical_name, props.match.params.id);
      setInitialForm(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

const resetCb = () => {
  fetchData();
}

  const fetchLotes = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name, { nivel: NIVEL_LOTE, no_father: true });
      setLotes(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
      fetchLotes();
    }
  }, [configSetup.client]);

  const nivelesJSX = Object.keys(NIVELES).map((key) => {
    return (<MenuItem value={key}>{NIVELES[key]}</MenuItem>)
  })

  const lotesJSX = lotes.map((lote) => {
    return (<MenuItem value={lote.id}>{lote.nombre}</MenuItem>)
  })


  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      cell: row => <button
      onClick={((evt) => {
        evt.preventDefault();
        update(configSetup.client.technical_name, row.id, {padre: null})
          .then(() => {
            disableLoading();
            fetchData();
            fetchLotes();
          })
          .catch(e => {
            console.error(e.response);
            disableLoading();
          });
      })}
                    id="kt_login_signin_submit"
                    type="submit"
                    className={`btn btn-primary`}
                  >
                    Remover
                  </button>
      ,
      allowOverflow: true,
      button: true,
    },
  ];

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/superficies'}
                name={'Superficies'}
                stage={'Edición'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Edici&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              nombre: initialForm.nombre,
              activo: initialForm.activo,
              nivel: initialForm.nivel,
              hectarea: initialForm.hectarea,
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                update(configSetup.client.technical_name, initialForm.id, {
                  nombre: values.nombre,
                  nivel: values.nivel,
                  hectarea: values.hectarea,
                  activo: values.activo})
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/superficies/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error editando superficie');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>
                <div className="form-group">
                  <FormControl className={classes.formControl}>
                    <TextField
                      type="number"
                      label="Hectáreas"
                      margin="normal"
                      className="kt-width-full"
                      name="hectarea"
                      value={values.hectarea}
                      onChange={handleChange}
                    />
              </FormControl>
              </div>
                <div className="form-group">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label">Nivel</InputLabel>
                        <Select
                          disabled
                          name="nivel"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.nivel}
                          onChange={handleChange}
                          /* onChange={(evt) => { */
                          /*   setInitialForm({...initialForm, nivel: evt.target.value}) */
                          /* }} */
                        >
                          {nivelesJSX}
                        </Select>
                      </FormControl>
                </div>
                <div className="form-group">
                  <LoteDialog
                    padre={initialForm}
                    cb={resetCb}
                  />
                </div>
                <div className="form-group">
              {initialForm.lotes !== null && (
                <DataTable
                  title="Lotes"
                  columns={columns}
                  data={initialForm.lotes || []}
                />
              )}
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/superficies/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(Edit);
