export const SET_EDAD_PLANTAS = 'SET_EDAD_PLANTAS';
export const ADD_EDAD_PLANTA = 'ADD_EDAD_PLANTA';
export const UPDATE_EDAD_PLANTA = 'UPDATE_EDAD_PLANTA';
export const REMOVE_EDAD_PLANTA = 'REMOVE_EDAD_PLANTA';
export const SET_CURRENT_EDAD_PLANTA = 'SET_CURRENT_EDAD_PLANTA';

export const initialState = {
  edad_plantas: [],
  current_edad_planta: undefined,
}

export default function reducer(state, action) {
  switch (action.type) {
    case SET_EDAD_PLANTAS:
      return {
        ...state,
        edad_plantas: action.payload
      };
    case ADD_EDAD_PLANTA:
      return {
        ...state,
        edad_plantas: [...state.todos, action.payload]
      };
case SET_CURRENT_EDAD_PLANTA:
      return {
        ...state,
        current_edad_planta: action.payload
      };
    case UPDATE_EDAD_PLANTA:
      const updatedItem = { ...action.payload };
      const updatedItemIndex = state.edad_plantas.findIndex(
        item => item.id === action.payload.id
      );
      const updatedPlantas = [
        ...state.edad_plantas.slice(0, updatedItemIndex),
        updatedItem,
        ...state.edad_plantas.slice(updatedItemIndex + 1)
      ];
      return {
        ...state,
        current_edad_planta: {},
        edad_plantas: updatedPlantas
      };
    case REMOVE_EDAD_PLANTA:
      // const removedPlantas = state.edad_plantas.filter(
      //   item => item.id !== action.payload.id
      // );
      const index = state.edad_plantas.findIndex(
        item => item.id === action.payload.id
      );
    if(index >=0) {
      const item = state.edad_plantas.find(
        item => item.id === action.payload.id
      );
      const updatedPlantas = [
        ...state.edad_plantas.slice(0, index),
        { ...item, activo: false },
        ...state.edad_plantas.slice(index + 1)
      ];

      return {
        ...state,
        current_edad_planta: {},
        edad_plantas: updatedPlantas
      };
    }
      // const isRemoved =
      //   state.current_edad_planta.id === action.payload.id ? {} : state.current_edad_planta;

      return {
        ...state,
        //current_edad_planta: isRemoved,
      };
    default:
      return state;
  }
}
