const moment = require("moment-timezone");

const DEFAULT_TIME_ZONE = "America/Guayaquil";

class DateHelper {
  static getYears(startYear) {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 2010;
    while (startYear <= currentYear) {
      years.unshift(startYear++);
    }
    return years;
  }

  static compareTime(str1, str2) {
    if (str1 === str2) {
      return 0;
    }
    const time1 = str1.split(":");
    const time2 = str2.split(":");
    if (parseInt(time1[0], 10) > parseInt(time2[0], 10)) {
      return 1;
    }
    if (
      parseInt(time1[0], 10) === parseInt(time2[0], 10) &&
      parseInt(time1[1], 10) > parseInt(time2[1], 10)
    ) {
      return 1;
    }
    return -1;
  }

  /*
      get Year with timezone
     */
  static getCurrentYear() {
    const currentYear = moment.tz(new Date(), DEFAULT_TIME_ZONE).format("YYYY");
    // return currentYear + 1900;
    return currentYear;
  }

  /*
   * @param rangofechas:  {inicio:date, final:date}
   * @return mismo objeto con horas y minutos formateados para cubrir el rango.
   */
  static setDateTimeFromRango(rangoFechas) {
    const { inicio, final } = rangoFechas;
    let realInicio = null;
    if(inicio instanceof Date) {
    realInicio = new Date(inicio.getTime());
    realInicio.setHours(0, 0, 0, 0);
    } else {
    realInicio = new Date(inicio);
    realInicio.setHours(0, 0, 0, 0);
    }

    final.setHours(23, 57, 59, 99999);
    return {
      inicio: realInicio,
      final
    };
  }

  /*
   * @param rangofechas:  {inicio:date, final:date}
   * @return {inicio:moment(date), final: moment(date)}.
   */
  static getMomentFromRangoFechas(rangoFechas) {
    const rangoConDateTimes = DateHelper.setDateTimeFromRango(rangoFechas);

    const { inicio, final } = rangoConDateTimes;
    return {
      inicio: moment(inicio.toString()).format("YYYY-MM-DD HH:mm"),
      final: moment(final.toString()).format("YYYY-MM-DD HH:mm")
    };
  }

  static getDate(date) {
    return moment(date.toString()).toDate();
  }

  static getDateFromString(date, format = null) {
    if (format) {
      return moment(date.toString()).format(format);
    }

    return moment.tz(date, DEFAULT_TIME_ZONE).toDate();
  }

  static getFormattedHoursMinutes(fecha) {
    const utcCutoff = moment(fecha);
    const displayCutoff = utcCutoff.clone().tz(DEFAULT_TIME_ZONE);
    const fechaFormatted = displayCutoff.format("HH:mm");
    return fechaFormatted;
  }

  static getFormattedDate(fecha) {
    const utcCutoff = moment(fecha);
    const displayCutoff = utcCutoff.clone().tz(DEFAULT_TIME_ZONE);
    const fechaFormatted = displayCutoff.format("YYYY-MM-DD HH:mm");
    return fechaFormatted;
  }

  static getFormattedTime(fecha) {
    const utcCutoff = moment(fecha);
    const displayCutoff = utcCutoff.clone().tz(DEFAULT_TIME_ZONE);
    const fechaFormatted = displayCutoff.format("HH:mm");
    return fechaFormatted;
  }

  static getDateArray(start, end) {
    const arr = [];
    const dt = new Date(start);

    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }

    return arr;
  }

  static getWeeksFromDate(date) {
    return moment(date, "YYYY-MM-DD").isoWeek();
  }

  static getWeeksFromMoment(momentDate) {
    return momentDate.isoWeek();
  }
}

export const MESES = {
  ENERO: {
    valor: 1,
    nombre: "Enero"
  },

  FEBRERO: {
    valor: 2,
    nombre: "Febrero"
  },
  MARZO: {
    valor: 3,
    nombre: "Marzo"
  },

  ABRIL: {
    valor: 4,
    nombre: "Abril"
  },

  MAYO: {
    valor: 5,
    nombre: "Mayo"
  },

  JUNIO: {
    valor: 6,
    nombre: "Junio"
  },

  JULIO: {
    valor: 7,
    nombre: "Julio"
  },

  AGOSTO: {
    valor: 8,
    nombre: "Agosto"
  },

  SEPTIEMBRE: {
    valor: 9,
    nombre: "Septiembre"
  },

  OCTUBRE: {
    valor: 10,
    nombre: "Octubre"
  },

  NOVIEMBRE: {
    valor: 11,
    nombre: "Noviembe"
  },

  DICIEMBRE: {
    valor: 12,
    nombre: "Diciembre"
  }
};

export default DateHelper;
