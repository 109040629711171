export const SET_DATA = 'SET_DATA';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE = 'UPDATE_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_CURR_ITEM = 'SET_CURR_ITEM';

export const SET_SUPS = 'SET_SUPS';
export const ADD_SUP = 'ADD_SUP';
export const UPDATE_SUP = 'UPDATE_SUP';
export const REMOVE_SUP = 'REMOVE_SUP';

export const initialState = {
  data: [] ,
  current: undefined,
  children: []
}

export default function reducer(state, action) {
  switch (action.type) {
    case SET_SUPS:
      return {
        ...state,
        children: action.payload
      };
    case ADD_SUP:
    const item = state.children.find((it) => it.id === action.payload.id);
    if(item) {
      return state;
    }
      return {
        ...state,
        children: [...state.children, action.payload]
      };

  case UPDATE_SUP: {
      const updatedItem = { ...action.payload };
      const updatedItemIndex = state.children.findIndex(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.children.slice(0, updatedItemIndex),
        updatedItem,
        ...state.children.slice(updatedItemIndex + 1)
      ];
      return {
        ...state,
        current: {},
        children: updatedData
      };

    }
  case REMOVE_SUP: {
      const index = state.children.findIndex(
        item => item.id === action.payload.id
      );
    if(index >=0) {
      const updatedData = [
        ...state.children.slice(0, index),
        ...state.children.slice(index + 1)
      ];
      return {
        ...state,
        children: updatedData
      };
    }
    break;
  }

    case SET_DATA:
      return {
        ...state,
        data: action.payload
      };
    case ADD_ITEM:
      return {
        ...state,
        data: [...state.data, action.payload]
      };
case SET_CURR_ITEM:
      return {
        ...state,
        current: action.payload
      };
    case UPDATE:
      const updatedItem = { ...action.payload };
      const updatedItemIndex = state.data.findIndex(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.data.slice(0, updatedItemIndex),
        updatedItem,
        ...state.data.slice(updatedItemIndex + 1)
      ];
      return {
        ...state,
        current: {},
        data: updatedData
      };
    case REMOVE_ITEM:
      const index = state.data.findIndex(
        item => item.id === action.payload.id
      );
    if(index >=0) {
      const item = state.data.find(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.data.slice(0, index),
        { ...item, activo: false },
        ...state.data.slice(index + 1)
      ];

      return {
        ...state,
        current: {},
        data: updatedData
      };
    }
      return {
        ...state,
      };
    default:
      return state;
  }
}
