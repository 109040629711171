import React, { useState, useEffect } from "react";

const Editable = ({
  submitFn,
  initialValue,
  childRef,
  text,
  type,
  placeholder,
  children,
  ref,
  isEditing,
  setEditing,
  ...props
}) => {

  const handleKeyDown = (event, type) => {
    const { key } = event;
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...keys, enterKey]; // All keys array

    /* 
    - For textarea, check only Escape and Tab key and set the state to false
    - For everything else, all three keys will set the state to false
  */
    if (key === enterKey) {
      setEditing(false)
      submitFn();
    } else if (
      (type === "textarea" && keys.indexOf(key) > -1) ||
      (type !== "textarea" && allKeys.indexOf(key) > -1)
    ) {
      setEditing(false);
    }
  };

  useEffect(() => {
    if (childRef && childRef.current && isEditing === true && childRef.current.tagName === 'input') {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);

  return (
    <section {...props}>
      {isEditing ? (
        <div
          contentEditable
          tabindex="0"
          onBlur={event => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
              setEditing(false);
            }
          }}
          onKeyDown={e => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <div onClick={() => {
          setEditing(true)
        }}>
          <span>{initialValue || "-"}</span>
        </div>
      )}
    </section>
  );
};

export default Editable;
