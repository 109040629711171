import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import CustomSnack from '../../../../partials/ui/snackContent';
import Actions from '../shared/CreateActions';
import { TextField } from "@material-ui/core";
import { get, list, update, remove, create } from "../../../../crud/umbral.crud";
import * as rangoUmbralAPI from "../../../../crud/rango_umbral.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import ConfigHeader from '../ConfigHeader'
import reducer,  * as ducks from './reducer';
import * as configurationConstants from '../../../../../helpers/ConfigurationConstants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Edit = props => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [openSnack, setOpenSnack] = useState(false);
  const [initialForm, setInitialForm] = useState({nombre: '', activo: null, rango_umbrales: []})
  const [ranges, setRanges] = useState({min: 0, max: 0, color: 1})
  const { COLORES } = configurationConstants;

  const coloresJSX = COLORES.map((item) => {
    return (<MenuItem value={item.num}>{item.value}</MenuItem>)
  })

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  }

  const rangoColumns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Color',
      selector: 'color',
      sortable: true,
      cell: row => 
        <div>
          { COLORES.find((item) => {
            return item.num === row.color
          }).value }
        </div>
    },
    {
      name: 'Minimo',
      selector: 'min',
      sortable: true,
    },
    {
      name: 'Maximo',
      selector: 'max',
      sortable: true,
    },
    {
      cell: row => <button
      onClick={((evt) => {
        evt.preventDefault();
        rangoUmbralAPI.remove(configSetup.client.technical_name, row.id).then((response) => {
          fetchData()
        }).catch((err) => {  });
      })}
                    id="kt_login_signin_submit"
                    type="submit"
                    className={`btn btn-primary`}
                  >
                    Remover
                  </button>
      ,
      allowOverflow: true,
      button: true,
    },
    ]

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await get(configSetup.client.technical_name, props.match.params.id);
      setInitialForm(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/umbral'}
                name={'Umbrales'}
                stage={'Lista'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Edici&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              nombre: initialForm.nombre,
              activo: initialForm.activo,
              edad_planta_nombre: initialForm.edad_planta_nombre
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                update(configSetup.client.technical_name, initialForm.id,
                       {
                         nombre: values.nombre,
                         activo: values.activo})
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/umbral/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando edad de planta');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}
                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre || ''}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    disabled
                    type="text"
                    label="Edad Planta"
                    margin="normal"
                    className="kt-width-full"
                    name="Edad Planta"
                    value={values.edad_planta_nombre || ''}
                    floatingLabelFixed={true}
                  />
                </div>

                <div className="form-group">
                  <DataTable
                    title="Rango de umbrales"
                    columns={rangoColumns}
                    data={initialForm.rango_umbrales}
                  />
                </div>
                <div className="form-group">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      Nuevo Rango
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type="number"
                        label="Minimo"
                        margin="normal"
                        className="kt-width-full"
                        name="min"
                        value={ranges.min}
                        onChange={(evt) => {
                          setRanges({
                            ...ranges,
                            min: evt.target.value
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type="number"
                        label="Maximo"
                        margin="normal"
                        className="kt-width-full"
                        name="max"
                        value={ranges.max}
                        onChange={(evt) => {
                          setRanges({
                            ...ranges,
                            max: evt.target.value
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Color</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ranges.color}
                          onChange={(evt) => {
                            setRanges({
                              ...ranges,
                              color: evt.target.value
                            })
                          }}
                        >
                          {coloresJSX}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <button
                    onClick={((evt) => {
                      evt.preventDefault();
                      const colorFound = initialForm.rango_umbrales.find((item) => {
                        return item.color === ranges.color
                      });
                      if(colorFound) {
                        setOpenSnack(true);
                      } else {
                        rangoUmbralAPI.create(configSetup.client.technical_name,
                                              {
                                                umbral: initialForm.id,
                                                ...ranges,
                                              })
                  .then((response) => {
                    disableLoading();
                    fetchData();
                    // history.push('/configuration/umbral/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                  });
                      }
                    })}
                    id="kt_login_signin_submit"
                    className={`btn btn-primary`}
                  >
                    Agregar Rango
                  </button>
                </div>
                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/umbral/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomSnack
        open={openSnack}
        setOpen={setOpenSnack}
        variant={'error'}
        message={'Error agregando!'}
        handleClose={handleClose}
      />
    </React.Fragment>
  )
}

export default React.memo(Edit);
