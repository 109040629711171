
import React, { useState, useContext, useEffect } from "react";
import {
  Grid
} from "@material-ui/core";
import styled from 'styled-components'
import { PropTypes } from 'prop-types';

import {
  BAJA,
  MODERADA,
  SEVERA,
  getIndicatorColor,
} from '../../../../../sigat/constants';

const Indicador = ({
  name, subtitle, peso, width, umbral
}) => {
  return (
    <Grid item xs={12} lg={3} md={4}>
      <div className="kt-space-20" />
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body kt-portlet__body--fluid">
          <div className="kt-widget26">
            <div className="kt-widget26__content">
              <span className="kt-widget26__desc" style={{
                'color': 'black',
              }}>{name || '  '}</span>
              <span className="kt-widget26__sub">{subtitle || ' - '}</span>
            </div>
          </div>
          <div>
            <span className="kt-widget26__number" style={{
              color: getIndicatorColor(umbral), 
              fontWeight: '500',
              fontSize: '40px'
            }}>{width || peso}</span>
          </div>
        </div>
      </div>
    </Grid>
  );
}

Indicador.propTypes = {
  name: PropTypes.string,
  age: PropTypes.number
};

export default React.memo(Indicador);
