import React, { Component, useMemo, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { emphasize, fade, makeStyles } from "@material-ui/core/styles";
import { components } from "../../../utils";
import { getDBFincas } from "../../../crud/db.crud";
import DateHelper from '../../../../helpers/DateHelper';
import DateRangePicker from '../../../widgets/DateRangePicker';
import * as filterSelector from "../../../store/ducks/filter.duck";

import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Popover
} from "@material-ui/core";

const smallDevice = window.matchMedia('(max-width: 400px)').matches;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    borderRadius:'4px'
  },
  AppBar: {
    borderRadius:'4px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Bar = () => {
  const classes = useStyles();

  const filters = useSelector(state => state.filter);
  const dispatch = useDispatch()
  const [fincas, setFincas] = useState([])
  const configSetup = useSelector(state => state.configuration);

  let fechas = {
    inicio: null,
    fin: null
  };

  try {
    fechas = {
    inicio: DateHelper.getDateFromString(filters.initialDate, 'YYYY-MM-DD'),
    fin: DateHelper.getDateFromString(filters.finalDate, 'YYYY-MM-DD')
  };
  } catch (err) {
    console.error(err);
  }  

  const fetchFincas = async () => {
    try {
      const response = await getDBFincas(configSetup.client.technical_name);
      setFincas(response.data)
    } catch (err) {
      setFincas([]);
    }
  }

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchFincas();
    }
  }, [configSetup, configSetup.client]);

  useEffect(() => {
    dispatch(filterSelector.actions.fetchAvailableDates(filters.finca === -1 ? null : filters.finca));
  }, [filters.finca]);

  /*
    AVAILABLE DATES CHANGED:
   */
  useEffect(() => {
    const { availableDates: dates, initialDate, finalDate } = filters
    if(dates.length > 0 && (!initialDate || !finalDate)) {
      let date = new Date(dates[0]);
      const minutes = date.getTimezoneOffset();
      date = new Date(date.getTime() + minutes * 60000);
      const rango = DateHelper.setDateTimeFromRango({
        inicio: date,
        final: date
      });

      dispatch(filterSelector.actions.setDates(rango.inicio, rango.final));
    }
  }, [filters.availableDates])

  const fincasJSX = fincas.map((item) => {
    return <MenuItem value={item.finca_id}>{ item.finca_nombre }</MenuItem>
  })

  const handleDateChange = (fechas) => {
    dispatch(filterSelector.actions.setDates(fechas.fecha_inicio, fechas.fecha_fin));
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default"
              className={classes.AppBar} >
        <Toolbar>
          <div className={classes.search}>
            <FormControl className={classes.formControl}>
              <InputLabel id="finca_label">Finca</InputLabel>
              <Select
                name="finca"
                labelId="finca_label"
                id="finca"
                value={Number.parseInt(filters.finca)}
                onChange={(evt) => {
                  dispatch(filterSelector.actions.setFinca(evt.target.value));
                }}
              >
                <MenuItem value={-1}>TODOS</MenuItem>
                {fincasJSX}
              </Select>
            </FormControl>
          </div>
      <div className={classes.grow}>
              <DateRangePicker
                dates={fechas}
                enabledDates={filters.availableDates}
                loadDataCB={handleDateChange}
              />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default React.memo(Bar);
