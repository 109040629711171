import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";

import ConfigHeader from "../ConfigHeader";
import DataTable from "../../IQDatatable";
import {
  getLeafEmissions,
  getFarmsLeafEmissions,
  changeLeafEmissionValue,
} from "../../../../crud/emision_foliar.crud";
import { getReportYears } from "../../../../crud/filter.crud";

const EmisionFoliarEdit = ({ data, update }) => {
  const dbTag = useSelector(state => state.configuration.client.technical_name);

  const { id, value } = data || {};
  const [leafEmisionValue, setLeafEmisionValue] = useState(value);

  const updateLeafEmission = async () => {
    try {
      await changeLeafEmissionValue(dbTag, id, leafEmisionValue);
      await update();
    } catch (err) {
      console.log(err);
    }
  };

  const changeValue = async e => {
    if (e.keyCode === 13) {
      const response = window.confirm(
        `¿Estas seguro que deseas cambiar esta emisión?`
      );
      if (response) {
        await updateLeafEmission();
      } else {
        setLeafEmisionValue(value);
      }
    }
  };
  return (
    <TextField
      type="number"
      margin="normal"
      className="kt-width-full"
      inputProps={{ step: "0.01" }}
      value={leafEmisionValue}
      onChange={e => setLeafEmisionValue(e.target.value)}
      onKeyUp={changeValue}
      disabled={!id}
    />
  );
};

const EmisionFoliar = () => {
  const dbTag = useSelector(state => state.configuration.client.technical_name);

  const [loading, setLoading] = useState(false);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(0);

  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState(0);

  const getLeafEmission = async () => {
    if (selectedYear > 0 && selectedFarm > 0) {
      try {
        setLoading(true);
        const { data } = await getLeafEmissions(
          dbTag,
          selectedYear,
          selectedFarm
        );
        setData(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const { data } = await getReportYears(dbTag);
        setYears(data);
        setSelectedYear(data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    const fetchFincas = async () => {
      try {
        const { data } = await getFarmsLeafEmissions(dbTag);
        setFarms(data);
        setSelectedFarm(data[0].id);

      } catch (err) {
        console.log(err);
      }
    };
    fetchYears();
    fetchFincas();
  }, [dbTag]);

  useEffect(() => {
    getLeafEmission();
  }, [selectedYear, selectedFarm, dbTag]);

  const [data, setData] = useState([]);

  const actions = (
    <>
      <Divider />
      <MenuItem>
        <FormControl>
          <InputLabel id="finca_label">Finca</InputLabel>
          <Select
            name="finca"
            labelId="finca_label"
            id="finca"
            value={selectedFarm}
            onChange={e => setSelectedFarm(e.target.value)}
          >
            {farms.map(f => (
              <MenuItem value={f.id}>{f.nombre}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </MenuItem>
      <MenuItem>
        <FormControl>
          <InputLabel id="anio_label">Año</InputLabel>
          <Select
            name="anio"
            labelId="anio_label"
            id="anio"
            value={selectedYear}
            onChange={e => setSelectedYear(e.target.value)}
          >
            {years.map(year => (
              <MenuItem value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </MenuItem>
    </>
  );

  const columns = [
    {
      name: "Foco",
      selector: "foco",
      width: "120px"
    },
    {
      name: "Fecha",
      selector: "fecha",
      width: "120px"
    },
    {
      name: "Semana",
      selector: "semana"
    },
    {
      name: "EM.1",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em1} />
    },
    {
      name: "EM.2",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em2} />
    },
    {
      name: "EM.3",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em3} />
    },
    {
      name: "EM.4",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em4} />
    },
    {
      name: "EM.5",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em5} />
    },
    {
      name: "EM.6",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em6} />
    },
    {
      name: "EM.7",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em7} />
    },
    {
      name: "EM.8",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em8} />
    },
    {
      name: "EM.9",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em9} />
    },
    {
      name: "EM.10",
      width: "80px",
      cell: row => <EmisionFoliarEdit update={getLeafEmission} data={row.em10} />
    },
    {
      name: "EF",
      selector: "ef"
    },
  ];

  return (
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={"/configuration/emision-foliar"}
                name={"Revisión Emisión Foliar"}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <LoadingOverlay
                active={loading}
                spinner
                text="Cargando información"
              >
                <div className="kt-portlet">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      Revisión Emisión Foliar
                    </div>
                  </div>
                  <div className="kt-portlet__body">
                    <DataTable
                      actions={actions}
                      highlightOnHover
                      columns={columns}
                      update={getLeafEmission} data={data}
                    />
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmisionFoliar;
