import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";

import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import { getMenuConfig, actions as buildActions } from "../../../_metronic/ducks/builder";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  Loading: "[LOADING], user",
  NewToken: "[TOKEN] New Auth Token Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined,
  loading: true,
  scope: undefined,
  expiresIn: undefined,
  expiresAt: undefined
};

export const reducer = persistReducer(
  { storage, key: "demo4-auth", whitelist: ["user", "authToken", "refreshToken", "scope", "expiresAt"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
    case actionTypes.Loading: {
      const { loading } = action.payload;
      return { ...state, loading };
    }
      case actionTypes.Login: {
        const { authToken, refreshToken, expiresIn, scope } = action.payload;

        return { expiresAt: new Date().getSeconds() + 36000, authToken, refreshToken, expiresIn, scope, user: undefined };
      }

      case actionTypes.NewToken: {
        const {
          access_token: accessToken,
          expires_in: expiresIn,
          refresh_token: refreshToken,
          scope } = action.payload;

        return { ...state, authToken: accessToken,
                 expiresIn,
                 refreshToken, scope,
                 expiresAt: new Date().getSeconds() + 36000 };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user, loading: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: ( payload ) => ({ type: actionTypes.Login, payload  }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const { data: user } = yield getUserByToken();
      yield put(actions.fulfillUser(user));
      // if (user.username !== 'agroaereocadmin') {
      //   const actualMenu = yield select(getMenuConfig);
      //   const items = actualMenu.items[1].submenu.filter(i => !i.title.toLowerCase().includes('relaci'));
      //   actualMenu.items[1].submenu = items;
      //   actualMenu.header.items[1].submenu = items;
      //   yield put(buildActions.setMenuConfig(actualMenu));
      // }
    } catch(e) {
      console.error(e);
    }
  });
}