import React, { useReducer, useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from '../../IQDatatable';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary'
import { MenuItem, FormControl, InputLabel, Select  } from "@material-ui/core";
import Actions from '../shared/CreateActions';
import DatatableMenu from '../shared/DatatableMenu';
import { list as listSuperficies } from "../../../../crud/superficie.crud";
import { TextField } from "@material-ui/core";
import { list, update, remove, create } from "../../../../crud/agrupacion.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import {
  years, weeks,
} from '../DateUtils';


import AgrupacionSuperficieDialog from './AgrupacionSuperficieDialog'

import reducer,  * as ducks from './createReducer';
import ConfigHeader from '../ConfigHeader'

const useStyles = makeStyles((theme) => ({
  header: {
    height: '30px',
    fontSize: '18px',
    marginTop: '10px'
  },
  divider: {
    borderTop: '20px solid'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    minWidth: '100%',
    margin: theme.spacing(1),
  }
}));

function ErrorFallback({error, componentStack, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const New = props => {

  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const yearsJSX = useMemo(() => {
    return years.map((item) => {
    return (<MenuItem value={item}>{item}</MenuItem>)
  })
  }, [years])

  const weeksJSX = useMemo(() => {
  return weeks.map((item) => {
    return (<MenuItem value={item}>{item}</MenuItem>)
  })
  }, [weeks]);



  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
  const [superficies, setSuperficies] = useState([]);
  const [supSelected, setSupSelected] = useState(null);
   const configSetup = useSelector(state => state.configuration);

  const superficiesJSX = superficies.map((item) => {
    return <option value={item.id}>{ item.nombre }</option>
  })

  const removeItem = async (fakeid) => {
    try {
      dispatch({type: ducks.RM_AGRUP_SUP, payload: fakeid});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  const fetchSuperficies = async () => {
    try {
      const response = await listSuperficies(configSetup.client.technical_name);
      setSuperficies(response.data)
      setSupSelected((response.data && response.data.length> 0 && response.data[0].id) || null)
    } catch (err) {
      console.error(err)
    } finally {}
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      // fetchData();
      fetchSuperficies();
    }
  }, [configSetup.client]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name);
      // dispatch({type: ducks.SET_DATA, payload: response.data});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => {
  return [
    {
      name: 'Nombre',
      selector: 'sup_data.nombre',
      sortable: true,
    },
    {
      name: 'Año',
      selector: 'anio',
      sortable: true,
    },
    {
      name: 'Semana de inicio',
      selector: 'semana_inicio',
      sortable: true,
    },
    {
      name: 'Semana de Fin',
      selector: 'semana_fin',
      sortable: true,
    },
    {
      cell: row => <button
      onClick={((evt) => {
        evt.preventDefault();
        removeItem(row.fakeid)
      })}
                    id="kt_login_signin_submit"
                    type="submit"
                    className={`btn btn-primary`}
                  >
                    Remover
                  </button>
      ,
      allowOverflow: true,
      button: true,
    },
  ]
  }, []);

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/agrupacion'}
                name={'Agrupaciones'}
                stage={'Nuevo'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Nuevo
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            initialValues={{
              nombre: "",
              activo: true 
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.nombre = 'Ingrese un nombre';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                create(configSetup.client.technical_name,
                       {
                         nombre: values.nombre,
                         activo: values.activo,
                         agrupacion_superficies: state.agrupacion_superficies.map((item) => {
                           return {
                             anio: item.anio,
                             semana_inicio: item.semana_inicio,
                             semana_fin: item.semana_fin,
                             superficie: item.superficie,
                           }
                         })
                       })
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/agrupacion/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre de Agrupación"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>
                <div className="form-group">
                  <FormControl className={classes.formControl}>
                    <AgrupacionSuperficieDialog
                      createDialog
                      padre={{nombre: values.nombre}}
                      cb={(data) => {
                        dispatch(
                          {type: ducks.ADD_AGRUP_SUP, payload: {
                            ...data,
                            sup_data: superficies.find((it) => {
                              return it.id === data.superficie
                            })
                          }
                          });
                      }}
                    />
                  </FormControl>
                </div>

                <div className="form-group">
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
                  <DataTable
                    title="Superficies"
                    columns={columns}
                    data={state.agrupacion_superficies}
                  />
  </ErrorBoundary>
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/agrupacion/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(New);
