import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import SupervisoresList from "./configuration/supervisores/list";
import SupervisoresEdit from "./configuration/supervisores/edit";
import SupervisoresNew from "./configuration/supervisores/new";

import Superficies from "./configuration/superficies/superficiesList";
import SuperficieEdit from "./configuration/superficies/superficiesEdit";
import SuperficieNew from "./configuration/superficies/superficiesNew";

import EdadPlantas from "./configuration/edadplantas/edadPlantasList";
import EdadPlantaEdit from "./configuration/edadplantas/edadPlantasEdit";
import EdadPlantaNew from "./configuration/edadplantas/edadPlantasNew";

import VariableMediciones from "./configuration/variable_medicion/variableMedicionesList";
import VariableMedicionEdit from "./configuration/variable_medicion/variableMedicionesEdit";
import VariableMedicionNew from "./configuration/variable_medicion/variableMedicionesNew";

import Agrupaciones from "./configuration/agrupacion/agrupacionesList";
import AgrupacionEdit from "./configuration/agrupacion/AgrupacionEdit";
import AgrupacionNew from "./configuration/agrupacion/AgrupacionNew";

import Umbrales from "./configuration/umbral/umbralList";
import UmbralesEdit from "./configuration/umbral/umbralEdit";
import UmbralesNew from "./configuration/umbral/umbralNew";

import Calificaciones from "./configuration/calificacion/calificacionesList";
import CalificacionEdit from "./configuration/calificacion/calificacionEdit";
import CalificacionNew from "./configuration/calificacion/calificacionesNew";

import GeneralList from "./configuration/general/List";
import GeneralEdit from "./configuration/general/Edit";
import GeneralNew from "./configuration/general/New";

import EmisionFoliar from "./configuration/emision-foliar";

import ConfigUI from './configuration/ConfigUI';

const Configuration = props => {
  return (
      <Switch>
        {<Redirect exact from="/configuration/edad_planta" to="/configuration/edad_planta/list" />}
        {<Redirect exact from="/configuration/variable_medicion" to="/configuration/variable_medicion/list" />}
        {<Redirect exact from="/configuration/calificacion_metodo" to="/configuration/calificacion_metodo/list" />}
        {<Redirect exact from="/configuration/agrupacion" to="/configuration/agrupacion/list" />}
        {<Redirect exact from="/configuration/umbral" to="/configuration/umbral/list" />}
        {<Redirect exact from="/configuration/superficies" to="/configuration/superficies/list" />}
        {<Redirect exact from="/configuration/general" to="/configuration/general/list" />}
        {<Redirect exact from="/configuration/supervisor" to="/configuration/supervisor/list" />}

        <Route exact path="/configuration/supervisor/list" component={SupervisoresList} />
        <Route exact path="/configuration/supervisor/edit/:id" component={SupervisoresEdit} />
        <Route exact path="/configuration/supervisor/new" component={SupervisoresNew} />

        <Route exact path="/configuration/superficies/list" component={Superficies} />
        <Route exact path="/configuration/superficies/edit/:id" component={SuperficieEdit} />
        <Route exact path="/configuration/superficies/new" component={SuperficieNew} />

        <Route exact path="/configuration" component={ConfigUI} />
        <Route exact path="/configuration/edad_planta/list" component={EdadPlantas} />
        <Route exact path="/configuration/edad_planta/edit/:id" component={EdadPlantaEdit} />
        <Route exact path="/configuration/edad_planta/new" component={EdadPlantaNew} />

        <Route exact path="/configuration/variable_medicion/list" component={VariableMediciones} />
        <Route exact path="/configuration/variable_medicion/edit/:id" component={VariableMedicionEdit} />
        <Route exact path="/configuration/variable_medicion/new" component={VariableMedicionNew} />

        <Route exact path="/configuration/agrupacion/list" component={Agrupaciones} />
        <Route exact path="/configuration/agrupacion/edit/:id" component={AgrupacionEdit} />
        <Route exact path="/configuration/agrupacion/new" component={AgrupacionNew} />

        <Route exact path="/configuration/calificacion_metodo/list" component={Calificaciones} />
        <Route exact path="/configuration/calificacion_metodo/edit/:id" component={CalificacionEdit} />
        <Route exact path="/configuration/calificacion_metodo/new" component={CalificacionNew} />

        <Route exact path="/configuration/umbral/list" component={Umbrales} />
        <Route exact path="/configuration/umbral/edit/:id" component={UmbralesEdit} />
        <Route exact path="/configuration/umbral/new" component={UmbralesNew} />

        <Route exact path="/configuration/general/list" component={GeneralList} />
        <Route exact path="/configuration/general/edit/:id" component={GeneralEdit} />
        <Route exact path="/configuration/general/new" component={GeneralNew} />
        
        <Route exact path="/configuration/emision-foliar/" component={EmisionFoliar} />
      </Switch>
  )
}

export default Configuration;
