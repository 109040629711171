export const SET_DATA = 'SET_DATA';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE = 'UPDATE_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_CURR_ITEM = 'SET_CURR_ITEM';

export const initialState = {
  data: [] ,
  current: undefined,
}

export default function reducer(state, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload
      };
    case ADD_ITEM:
      return {
        ...state,
        data: [...state.data, action.payload]
      };
case SET_CURR_ITEM:
      return {
        ...state,
        current: action.payload
      };
    case UPDATE:
      const updatedItem = { ...action.payload };
      const updatedItemIndex = state.data.findIndex(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.data.slice(0, updatedItemIndex),
        updatedItem,
        ...state.data.slice(updatedItemIndex + 1)
      ];
      return {
        ...state,
        current: {},
        data: updatedData
      };
    case REMOVE_ITEM:
      const index = state.data.findIndex(
        item => item.id === action.payload.id
      );
    if(index >=0) {
      const item = state.data.find(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.data.slice(0, index),
        { ...item, activo: false },
        ...state.data.slice(index + 1)
      ];

      return {
        ...state,
        current: {},
        data: updatedData
      };
    }
      return {
        ...state,
      };
    default:
      return state;
  }
}
