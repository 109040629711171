export const TIPOS_UMBRAL = {
  [1]: 'BAJO',
  [2]: 'MODERADO',
  [3]: 'SEVERO',
  [4]: 'H3_PL',
  [5]: 'H3_ML',
  [6]: 'H4_PL',
  [7]: 'H4_ML',
  [8]: 'H5_PL',
  [9]: 'H5_ML',
  [10]: 'HT',
  [11]: 'HVLE',
  [12]: 'HVLQ_LESS',
  [13]: 'HVLQ_MORE',
};

export const COLORES = [
  { num: 1, value: 'Amarillo'},
  { num: 2, value: 'Verde'},
  { num: 3, value: 'Rojo'},
  ]

