export const ADD_AGRUP_SUP = 'ADD_AGRUP_SUP';
export const RM_AGRUP_SUP = 'RM_AGRUP_SUP';

export const SET_NAME = 'SET_NAME';

export const initialState = {
  name: undefined,
  agrupacion_superficies: []
}

export default function reducer(state, action) {
 switch (action.type) {
 case SET_NAME: {
   return {
     ...state,
     name: action.payload
   }

 }
 case ADD_AGRUP_SUP: {
   return {
     ...state,
     agrupacion_superficies: [...state.agrupacion_superficies, { fakeid: new Date().getTime(), ...action.payload }]
   }
 }

 case RM_AGRUP_SUP: {
   const fakeid = action.payload
   const agrupacion_superficies = state.agrupacion_superficies.filter((item) => item.fakeid !== fakeid)

   return {
     ...state,
     agrupacion_superficies
   }
 }

 default:
   break;
 }
}
