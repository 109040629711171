import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import { TextField } from "@material-ui/core";
import { get, list, update, remove, create } from "../../../../crud/calificacion_metodo.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import Actions from '../shared/CreateActions';

import ConfigHeader from '../ConfigHeader'
import reducer,  * as ducks from './reducer';


const Edit = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [initialForm, setInitialForm] = useState({nombre: '', activo: null})

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await get(configSetup.client.technical_name, props.match.params.id);
      setInitialForm(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  console.info(initialForm)
  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/calificacion_metodo'}
                name={'Métodos de Calificación'}
                stage={'Edición'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Edici&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              nombre: initialForm.nombre,
              descripcion: initialForm.descripcion,
              castigo: initialForm.castigo,
              valor: initialForm.valor,
              activo: initialForm.activo 
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              }
              if (!values.descripcion) {
                errors.descripcion = 'Ingrese una descripcion';
              } 
              if (!values.castigo) {
                errors.castigo = 'Ingrese un castigo';
              } 

              if (!values.valor) {
                errors.valor = 'Ingrese un valor';
              } 

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                update(configSetup.client.technical_name, initialForm.id, {
                  nombre: values.nombre,
                  activo: values.activo,
                  valor: values.valor,
                  descripcion: values.descripcion, castigo: values.castigo})
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/calificacion_metodo/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando edad de planta');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    floatingLabelFixed={true}
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre || ''}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    floatingLabelFixed={true}
                    type="text"
                    label="Descripcion"
                    margin="normal"
                    className="kt-width-full"
                    name="descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.descripcion || ''}
                    helperText={touched.descripcion && errors.descripcion}
                    error={Boolean(touched.descripcion && errors.descripcion)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    floatingLabelFixed={true}
                    type="number"
                    label="Castigo"
                    margin="normal"
                    className="kt-width-full"
                    name="castigo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.castigo || ''}
                    helperText={touched.castigo && errors.castigo}
                    error={Boolean(touched.castigo && errors.castigo)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    floatingLabelFixed={true}
                    type="number"
                    label="Valor"
                    margin="normal"
                    className="kt-width-full"
                    name="valor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.valor || ''}
                    helperText={touched.valor && errors.valor}
                    error={Boolean(touched.valor && errors.valor)}
                  />
                </div>
                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/calificacion_metodo/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(Edit);
