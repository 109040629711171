import React, { useMemo, useState, useContext, useEffect } from "react";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { PropTypes } from 'prop-types';

import Autosuggest from 'react-autosuggest';
import { TextField } from "@material-ui/core";

import theme from './suggestionTheme.css'

const renderSuggestion = suggestion => (
  <div>
  {suggestion.nombre}
  </div>
);

const filter = createFilterOptions();

const DepartmentAutoSuggest = ({
  value,
  setValue,
  items,
}) => {
  return (
      <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            nombre: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            nombre: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            nombre: `Agregar "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={items}
      getOptionLabel={(option) => {
        console.info(option)
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.nombre;
      }}
      renderOption={(option) => option.nombre}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Seleccione departamento" variant="outlined" />
      )}
    />  )
}

export default React.memo(DepartmentAutoSuggest);
