
const LUNES = 1;
const DOMINGO = 2;

export const DOS = 1;
export const TRES = 2;

export const dias = {
  [LUNES]: 'Lunes',
  [DOMINGO]: 'Domingo'
}

export const semanas = {
  [DOS]: 'Semana 52',
  [TRES]: 'Semana 53'
}
