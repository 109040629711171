import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, takeEvery, select, call } from "redux-saga/effects";
import * as routerHelpers from "../../router/RouterHelpers";
import {
  getReportYears,
  getReportWeeks,
  getReportFincas,
  getReportGroups,
  getReportLotes,
} from '../../crud/filter.crud';

export const actionTypes = {
  FetchReportYears: "[FetchReportYears] Action",
  FetchReportWeeks: "[FetchReportWeeks] Action",
  FetchReportFincas: "[FetchReportFincas] Action",
  FetchReportGroups: "[FetchReportGroups] Action",
  FetchReportLotes: "[FetchReportLotes] Action",

  /* Used to set the final filter */
  SetReportFilters: "[SetReportFilters] Action",
  SetReportFilter: "[SetReportFilter] Action",
  SetReportData: "[SetReportData] Action",
};

const initialState = {
  years: [],
  weeks: [],
  fincas: [],
  groups: [],
  lotes: [],

  /* Filters */
  filters: {
    year: null,
    week: null,
    finca: null,
    group: null,
    lote: null,
  }
};

const reqPromise = async (request, dbTag, params) => {
  let data = []
  try {
    const response = await request(dbTag, params);
    data = response.data
  } catch (err) {
    console.error(err);
    data = []
  } finally {
    return data;
  }
};


export const getConfigSetup = state => state.configuration;
export const getReportFilterSetup = state => state.reportFilter;

// FIXME all these functions should be merged into only one function

function* fetchReportYears() {

  let data = {
    years: [],
    weeks: [],
    fincas: [],
    groups: [],
    lotes: [],
  }

  let filters = {
    year: null,
    week: null,
    finca: null,
    group: null,
    lote: null,
  }

  let configuration = yield select(getConfigSetup);
  const dbTag = configuration.client.technical_name;
  const responseYears = yield reqPromise(getReportYears, dbTag, {});

  if (responseYears.length === 0) {
    yield put({type: actionTypes.SetReportData, payload: data});
    yield put({type: actionTypes.SetReportFilters, payload: filters});
    return
  }

  data.years = responseYears
  filters.year = responseYears.length > 0  && responseYears[0]

  const responseWeeks = yield reqPromise(
    getReportWeeks,
    dbTag,
    filters
  );

  data.weeks = responseWeeks
  filters.week = responseWeeks.length > 0  && responseWeeks[0]

  const responseFincas = yield reqPromise(
    getReportFincas,
    dbTag,
    filters
  );

  data.fincas = responseFincas
  filters.finca = responseFincas.length> 0 && responseFincas[0].sup_id

  const responseGroups = yield reqPromise(
    getReportGroups,
    dbTag,
    filters
  );

  data.groups = responseGroups

  const responseLotes = yield reqPromise(
    getReportLotes,
    dbTag,
    filters
  );

  data.lotes = responseLotes

  yield put({type: actionTypes.SetReportData, payload: data});
  yield put({type: actionTypes.SetReportFilters, payload: filters })
};

function* fetchReportWeeks(filter) {
  let reportFilter = yield select(getReportFilterSetup);
  let configuration = yield select(getConfigSetup);
  const dbTag = configuration.client.technical_name;

  let data = {
    years: reportFilter.years,
    weeks: [],
    fincas: [],
    groups: [],
    lotes: [],
  }
  let filters = {...reportFilter.filters, ...filter, group: null, lote: null}

  const responseWeeks = yield reqPromise(
    getReportWeeks,
    dbTag,
    filters
  );
  data.weeks = responseWeeks
  filters.week = responseWeeks.length > 0  && responseWeeks[0]

  const responseFincas = yield reqPromise(
    getReportFincas,
    dbTag,
    filters
  );
  data.fincas = responseFincas
  filters.finca = responseFincas.length> 0 && responseFincas[0].sup_id

  const responseGroups = yield reqPromise(
    getReportGroups,
    dbTag,
    filters
  );
  data.groups = responseGroups

  const responseLotes = yield reqPromise(
    getReportLotes,
    dbTag,
    filters
  );
  data.lotes = responseLotes

  yield put({type: actionTypes.SetReportData, payload: data})
  yield put({type: actionTypes.SetReportFilters, payload: filters})
}

function* fetchReportFincas(filter) {
  let reportFilter = yield select(getReportFilterSetup);
  let configuration = yield select(getConfigSetup);
  const dbTag = configuration.client.technical_name;

  let data = {
    years: reportFilter.years,
    weeks: reportFilter.weeks,
    fincas: [],
    groups: [],
    lotes: [],
  }
  let filters = {...reportFilter.filters, ...filter, group: null, lote: null}

  const responseFincas = yield reqPromise(
    getReportFincas,
    dbTag,
    filters
  );
  data.fincas = responseFincas
  filters.finca = responseFincas.length> 0 && responseFincas[0].sup_id

  const responseGroups = yield reqPromise(
    getReportGroups,
    dbTag,
    filters
  );
  data.groups = responseGroups

  const responseLotes = yield reqPromise(
    getReportLotes,
    dbTag,
    filters
  );
  data.lotes = responseLotes

  yield put({type: actionTypes.SetReportData, payload: data})
  yield put({type: actionTypes.SetReportFilters, payload: filters})
};

function* fetchReportGroups(filter) {
  let reportFilter = yield select(getReportFilterSetup);
  let configuration = yield select(getConfigSetup);
  const dbTag = configuration.client.technical_name;

  let data = {
    years: reportFilter.years,
    weeks: reportFilter.weeks,
    fincas: reportFilter.fincas,
    groups: [],
    lotes: [],
  }
  let filters = {...reportFilter.filters, ...filter, group: null, lote: null}

  const responseGroups = yield reqPromise(
    getReportGroups,
    dbTag,
    filters
  );
  data.groups = responseGroups

  const responseLotes = yield reqPromise(
    getReportLotes,
    dbTag,
    filters
  );
  data.lotes = responseLotes

  yield put({type: actionTypes.SetReportData, payload: data})
  yield put({type: actionTypes.SetReportFilters, payload: filters})
};

function* fetchReportLotes(filter) {
  let reportFilter = yield select(getReportFilterSetup);
  let configuration = yield select(getConfigSetup);
  const dbTag = configuration.client.technical_name;

  let data = {
    years: reportFilter.years,
    weeks: reportFilter.weeks,
    fincas: reportFilter.fincas,
    groups: reportFilter.groups,
    lotes: [],
  }
  let filters = {...reportFilter.filters, ...filter, lote: null}

  const responseLotes = yield reqPromise(
    getReportLotes,
    dbTag,
    filters
  );
  data.lotes = responseLotes

  yield put({type: actionTypes.SetReportData, payload: data})
  yield put({type: actionTypes.SetReportFilters, payload: filters})
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetReportData: {
        return { ...state, ...action.payload };
      }

      case actionTypes.SetReportFilters: {
        if(action.payload.filter)
          return { ...state, filters: { ...state.filters, ...action.payload.filter } };
        else
        return { ...state, filters: { ...state.filters, ...action.payload } };
      }

      case actionTypes.SetReportFilter: {
        if(action.payload.filter)
          return { ...state, filters: { ...state.filters, ...action.payload.filter } };
      }

      default:
        return state;
    }
  }

export const actions = {
  fetchReportYears: () => ({ type: actionTypes.FetchReportYears, payload: { } }),
  fetchReportWeeks: (filter) => ({ type: actionTypes.FetchReportWeeks, payload: { filter } }),
  fetchReportFincas: (filter) => ({ type: actionTypes.FetchReportFincas, payload: { filter } }),
  fetchReportGroups: (filter) => ({ type: actionTypes.FetchReportGroups, payload: { filter } }),
  fetchReportLotes: (filter) => ({ type: actionTypes.FetchReportLotes, payload: { filter } }),

  setReportFilters: filters => ({ type: actionTypes.SetReportFilters, payload: { ...filters } }),
  setReportFilter: filter => ({ type: actionTypes.SetReportFilter, payload: { filter } }),
};

export function* saga() {
  yield takeLatest(actionTypes.FetchReportYears, function* fetchYearsSaga() {
    yield call(fetchReportYears)
  });

  yield takeLatest(actionTypes.FetchReportWeeks, function* fetchWeeksSaga({ payload }) {
    yield call(fetchReportWeeks, payload.filter);
  });

  yield takeLatest(actionTypes.FetchReportFincas, function* fetchFincasSaga({ payload }) {
    yield call(fetchReportFincas, payload.filter);
  });

  yield takeLatest(actionTypes.FetchReportGroups, function* fetchGroupsSaga({ payload }) {
    yield call(fetchReportGroups, payload.filter);
  });

  yield takeLatest(actionTypes.FetchReportLotes, function* fetchLotesSaga({ payload }) {
    yield call(fetchReportLotes, payload.filter);
  });
}
