export const SET_SUPS = 'SET_SUPS';
export const ADD_SUP = 'ADD_SUP';
export const UPDATE_SUP = 'UPDATE_SUP';
export const REMOVE_SUP = 'REMOVE_SUP';

export const initialState = {
  children: [],
}

export default function reducer(state, action) {
  switch (action.type) {
    case SET_SUPS:
      return {
        ...state,
        children: action.payload
      };
    case ADD_SUP:
    const item = state.children.find((it) => it.nombre === action.payload.nombre);
    if(item) {
      return state;
    }
      return {
        ...state,
        children: [...state.children, action.payload]
      };

  case UPDATE_SUP: {
      const updatedItem = { ...action.payload };
      const updatedItemIndex = state.children.findIndex(
        item => item.nombre === action.payload.nombre
      );
      const updatedData = [
        ...state.children.slice(0, updatedItemIndex),
        updatedItem,
        ...state.children.slice(updatedItemIndex + 1)
      ];
      return {
        ...state,
        children: updatedData
      };

    }
  case REMOVE_SUP: {
      const index = state.children.findIndex(
        item => item.nombre === action.payload.nombre
      );
    if(index >=0) {
      const updatedData = [
        ...state.children.slice(0, index),
        ...state.children.slice(index + 1)
      ];
      return {
        ...state,
        children: updatedData
      };
    }
    break;
  }

    default:
      return state;
  }
}
