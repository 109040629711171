const axios = require("axios");

export const baseURL =
  process.env.NODE_ENV === "production"
    ? `https://sigat-v2.procesosiq.com/api`
    : `http://localhost:8000/api`;

export const axiosClient = axios.create({ baseURL });

export const CONFIGURATION_URL = `${baseURL}/v1/configuration/`;

export const setConfig = (config, tag) =>
  axiosClient.post(CONFIGURATION_URL, { tag, config });
export const getConfig = tag => axiosClient.get(CONFIGURATION_URL, { tag });
