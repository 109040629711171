const currentYear = parseInt(new Date().getFullYear())
const countYears = 10;
const countWeeks = 53;

const counter = Array.from(Array(countYears).keys())
const counterWeeks = Array.from(Array(countWeeks).keys());

const years = counter.map((item, index) => {
  return currentYear - index - 1;
});

const weeks = counterWeeks.map((item, index) => {
  return countWeeks - index - 1;
});

years.unshift(currentYear);

export {
  years,
  weeks,
}
