import React, { useReducer, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from 'react-data-table-component';

import ConfirmDialog from '../../../../widgets/ConfirmDialog'
import { choices } from '../../../../../sigat/constants'
import { list, update, remove, create } from "../../../../crud/calificacion_metodo.crud";
import { metodologiaLinks } from "../../../../crud/configuracionInicial.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Switch,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import ConfigHeader from '../ConfigHeader'
import Button from '../shared/Button';
import DatatableMenu from '../shared/DatatableMenu';
import CalificacionBar from './CalificacionBar';

import LoadingOverlay from 'react-loading-overlay';
import reducer,  * as ducks from './reducer';


const List = props => {
  const history = useHistory();

const New = ({ onNew }) => (
  <Button onClick={e => onNew()}>Nuevo</Button>
);
  const actionsMemo = React.useMemo(() => [<New onNew={() => {
                                             history.push('/configuration/calificacion_metodo/new')
                                           }} />], []);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [metodoLoading, setMetodoLoading] = useState(true);
  const [metodo, setMetodo] = useState(null);
  const [metodoForm, setMetodoForm] = useState(null);
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchMetodologia();
    }
  }, [configSetup.client]);

  useEffect(() => {
    if(metodo) {
      fetchData();
    }
  }, [configSetup.client, metodo]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name, { tipo_metodo: metodo.nombre });
      dispatch({type: ducks.SET_DATA, payload: response.data});
    } catch (err) {
      dispatch({type: ducks.SET_DATA, payload: [] });
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  const fetchMetodologia = async () => {
    setMetodoLoading(true);
    try {
      const response = await metodologiaLinks.list(configSetup.client.technical_name);
      setMetodo(response.data.length > 0 && response.data[0]);
    } catch (err) {
      console.error(err)
    } finally {
      setMetodoLoading(false);
    }
  };

  const removeData = async (id) => {
    setLoading(true);
    try {
      const response = await remove(configSetup.client.technical_name, id);
      dispatch({type: ducks.REMOVE_ITEM, payload: {id}});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (evt, id) => {
    update(configSetup.client.technical_name, id, {activo: evt.target.checked}).then((response) => {
      dispatch({type: ducks.UPDATE, payload: {...response.data}});
    }).catch((err) => {
      console.error(err);
    });
  };

  const onChangeMetodo = (value) => {
    setMetodoForm(value)
    setOpenDialog(true);
  };

  const onConfirm = () => {
    metodologiaLinks.create(configSetup.client.technical_name, {
      nombre: metodoForm
    }).then((response) => {
      setMetodo(response.data);
    });
    setOpenDialog(false);
  };

  const onClose = () => {
    setMetodoForm(null);
  };

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      name: 'Descripción',
      selector: 'descripcion',
      sortable: true,
    },
    {
      name: 'Castigo',
      selector: 'castigo',
      sortable: true,
    },
    {
      name: 'Valor',
      selector: 'valor',
      sortable: true,
    },
    {
      name: 'Activo',
      selector: 'activo',
      cell: row => <Switch
        checked={row.activo}
                     onChange={(evt) => {handleChange(evt, row.id)}}
        color="primary"
        name="active"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    },
    {
      cell: row => <DatatableMenu
                     justEdit
                     size="small" row={row}
      onDeleteRow={() => {
        removeData(row.id);
      }}
      onEditRow={() => {
        history.push(`/configuration/calificacion_metodo/edit/${row.id}`)
      }}
        />,
      allowOverflow: true,
      button: true,
      width: '56px',
    },
  ];

  let childComponent = (
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <CalificacionBar
              metodo={metodo}
              onChangeMetodo={onChangeMetodo}
              fetchMetodologia={fetchMetodologia}
            />
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
<LoadingOverlay
  active={loading}
  spinner
  text='Cargando información'
  >
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    M&eacute;todos de Calificaci&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
  <DataTable
    actions={actionsMemo}
    title=""
    columns={columns}
    data={state.data}
  />
                  </div>
              </div>
</LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
  );
  if(!metodo) {
    childComponent = (
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <CalificacionBar
              metodo={metodo}
              onChangeMetodo={onChangeMetodo}
              fetchMetodologia={fetchMetodologia}
            />
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    M&eacute;todos de Calificaci&oacute;n
                  </div>
                </div>
                <div className="kt-portlet__body">
                  {(metodoLoading && 'Cargando...')
                   || 'No hay información. Escoja un método para continuar'}
                  
                </div>
              </div>
      </div>
      </div>
    )
  }

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/calificacion_metodo'}
                name={'Métodos de Calificación'}
                stage={'Lista'}
              />
            </div>
          </div>
        </div>
      </div>
      {childComponent}
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        message={'¿Está seguro de que desea escoger este método de calificación?'}
        title={'Selección de Método de calificación'}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </React.Fragment>
  )
}

export default React.memo(List);
