import DataTable from 'react-data-table-component';
import React, { useState, useContext, useEffect } from "react";
import { PropTypes } from 'prop-types';

const IQDataTable = props => {
  return (
      <DataTable {...props}
       noDataComponent={'No hay información'}
      >
      {props.children}
      </DataTable>
  );
}

export default React.memo(IQDataTable); 
