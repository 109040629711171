import * as crudConfig from "./config.crud";

const { axiosClient, baseURL } = crudConfig;

export const DB_URL = `${baseURL}/v1/sigatoka/evaluacion_sigatoka/report/`;
export const DB_YEARS_URL = `${baseURL}/v1/sigatoka/db/years/`;
export const DB_DATES_URL = `${baseURL}/v1/sigatoka/db/dates/`;
export const DB_FINCAS_URL = `${baseURL}/v1/sigatoka/db/roots/`;
export const DB_UPLOAD_URL = `${baseURL}/v1/sigatoka/db/upload/`;
export const DB_DOWNLOAD_URL = `${baseURL}/v1/sigatoka/db/download/`;


export const getDB = (technical_name, finca, year, agrupacion_nombre, fecha_inicio, fecha_fin) =>
  axiosClient.get(DB_URL, {
    params: { db_tag: technical_name, finca: finca === -1 ? null: finca, year, agrupacion_nombre,
              fecha_inicio, fecha_fin }
  });

export const getDBYears = (technical_name) =>
  axiosClient.get(DB_YEARS_URL, {
    params: { db_tag: technical_name }
  });

export const getDBDates = (technical_name, params) =>
  axiosClient.get(DB_DATES_URL, {
    params: { db_tag: technical_name, ...params}
  });

export const getDBFincas = (technical_name) =>
  axiosClient.get(DB_FINCAS_URL, {
    params: { db_tag: technical_name }
  });

export const uploadDB = (formData) =>
  axiosClient.post(DB_UPLOAD_URL, formData);


export const downloadDB = (technical_name, formData) => {
  return axiosClient.get(DB_DOWNLOAD_URL, {
    responseType: 'blob',
    params: { db_tag: technical_name, ...formData}
  });
}
