import React, { useMemo, useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from '../../IQDatatable';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import { NIVEL_LOTE, NIVEL_FINCA, NIVELES } from '../../../../../sigat/constants';
import Actions from '../shared/CreateActions';
import DatatableMenu from '../shared/DatatableMenu';
import { TextField } from "@material-ui/core";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Button, 
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio,
} from "@material-ui/core";

import { list, update, remove, create } from "../../../../crud/empleado.crud";
import { listDepartamentos } from "../../../../crud/departamento.crud";
import { list as listSuperficies } from "../../../../crud/superficie.crud";
import { list as listCargos } from "../../../../crud/cargo.crud";

import CargoDialog from './cargoDialog'
import reducer,  * as ducks from './createReducer';
import ConfigHeader from '../ConfigHeader'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const New = props => {

  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
  const [cargos, setCargos] = useState([]);
  const [superficies, setSuperficies] = useState([]);
  const [supSelected, setSupSelected] = useState(null);
   const configSetup = useSelector(state => state.configuration);


  const handleAddFinca = () => {
    if(supSelected) {
      const sup = superficies.find((item) => item.id.toString() === supSelected.toString())
      dispatch({type: ducks.ADD_SUP, payload: sup});
    }
  }

  const fetchCargos = async () => {
    try {
      const response = await listCargos(configSetup.client.technical_name);
      setCargos(response.data)
    } catch (err) {
      console.error(err)
    } finally {}
  };

  const fetchFincas = async () => {
    try {
      const response = await listSuperficies(configSetup.client.technical_name, {nivel: NIVEL_FINCA});
      setSuperficies(response.data)
      setSupSelected((response.data && response.data.length> 0 && response.data[0].id) || null)
    } catch (err) {
      console.error(err)
    } finally {}
  };

  const cbGetData = (data) => {
    fetchCargos()
  }

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchCargos();
      fetchFincas();
    }
  }, [configSetup.client]);

  const cargosJSX = useMemo(() => {
    return cargos.map((item) => {
    return (<MenuItem value={item.id}>{`Departamento: ${item.departamento.nombre} Cargo: ${item.nombre}`}</MenuItem>)
    });
  }, [cargos,])

  const fincasJSX = useMemo(() => {
    return superficies.map((item) => {
      return <option value={item.id}>{ item.nombre }</option>
    })
  }, [superficies, ])
  const columns = [
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      name: 'Hectáreas',
      selector: 'hectarea',
      sortable: true,
    },
  ];

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/supervisor'}
                name={'Supervisores'}
                stage={'Nuevo'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Nuevo
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            initialValues={{
              nombre: "",
              apellido: "",
              telefono: "",
              cedula: "",
              fecha_nacimiento: "",
              departamento: "",
              cargo: "Supervisor",
              superficies: state.children,
              cargo_id: null,
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 

              if (!values.cedula) {
                errors.cedula = 'Ingrese una cédula';
              } 

              if (!values.fecha_nacimiento) {
                errors.fecha_nacimiento = 'Ingrese una fecha de nacimiento';
              } 


              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                create(configSetup.client.technical_name,
                       {
                         persona: {
                           nombre: values.nombre,
                           apellido: values.apellido,
                           cedula: values.cedula,
                           telefono: values.telefono,
                           fecha_nacimiento: values.fecha_nacimiento,
                         },
                         cargo: values.cargo_id,
                         superficies: state.children,
                       })
                  .then(() => {
                    disableLoading();
                    setSubmitting(false);
                    history.push('/configuration/supervisor/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Apellido"
                    margin="normal"
                    className="kt-width-full"
                    name="apellido"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.apellido}
                    helperText={touched.apellido && errors.apellido}
                    error={Boolean(touched.apellido && errors.apellido)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Teléfono"
                    margin="normal"
                    className="kt-width-full"
                    name="telefono"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.telefono}
                    helperText={touched.telefono && errors.telefono}
                    error={Boolean(touched.telefono && errors.telefono)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Cédula"
                    margin="normal"
                    className="kt-width-full"
                    name="cedula"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cedula}
                    helperText={touched.cedula && errors.cedula}
                    error={Boolean(touched.cedula && errors.cedula)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="date"
                    margin="normal"
                    className="kt-width-full"
                    id="fecha_nacimiento"
                    label="Fecha de nacimiento"
                    value={values.fecha_nacimiento}
                    helperText={touched.fecha_nacimiento && errors.fecha_nacimiento}
                    error={Boolean(touched.fecha_nacimiento && errors.fecha_nacimiento)}
                    className={classes.textField}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="form-group">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label">Cargo</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.cargo_id}
                          name="cargo_id"
                          onChange={handleChange}
                        >
                          {cargosJSX}
                        </Select>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                  <CargoDialog
                    padre={{ cedula: values.cedula }}
                    createMode
                    createCB={cbGetData}
                  />
                      </FormControl>
                </div>

                <div className="form-group">
                  <FormControl className={classes.formControl}>
                <select onChange={(evt) => {
                  setSupSelected(Number(evt.target.value))
                }}>
                  {fincasJSX}
                </select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <Button variant="outlined" color="primary" onClick={handleAddFinca}>
                      Agregar Finca
                    </Button>
                  </FormControl>
                </div>
                <div className="form-group">
                  {state.children !== null && (
                <DataTable
                  title="Superficies"
                  columns={columns}
                  data={state.children || []}
                />
              )}
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/supervisor/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(New);
