import * as crudConfig from "./config.crud";

const { axiosClient, baseURL } = crudConfig;

export const URL = `${baseURL}/v1/sigatoka/muestra_sigatoka_rv/`;

export const list = (technical_name, finca, year) =>
  axiosClient.get(URL, {
    params: { db_tag: technical_name }
  });

export const get = (technical_name, id) =>
  axiosClient.get(`${URL}${id}/`, {
    params: { db_tag: technical_name }
  });

export const update = (technical_name, id, data) => axiosClient.patch(`${URL}${id}/`, {...data, db_tag: technical_name});
export const create = (technical_name, data) => axiosClient.post(URL, { ...data, db_tag: technical_name });
export const remove = (technical_name, id = '') => axiosClient.delete(`${URL}${id}/`,  { data: { db_tag: technical_name }});
