import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import Popover from '@material-ui/core/Popover';
import es from 'react-date-range/dist/locale';
import { PropTypes } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {
  withStyles
} from '@material-ui/core/styles';
import { defaultInputRanges, defaultStaticRanges } from '../../helpers/CalendarRanges';

import styles from './barStyles';
import DateHelper from '../../helpers/DateHelper';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const IQDateRangePicker = ({
  dates,
  loadDataCB,
  classes,
  enabledDates
}) => {
  const [anchorEl, setAnchor] = useState(null);
  const openCalendar = Boolean(anchorEl);

  const handleOpenCalendar = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchor(null);
  };

  const fechas = {
    inicio: dates.inicio && DateHelper.getDateFromString(dates.inicio, 'YYYY-MM-DD'),
    fin: dates.fin && DateHelper.getDateFromString(dates.fin, 'YYYY-MM-DD')
  };

  const handleDateChange = (evt) => {
    const rango = DateHelper.setDateTimeFromRango({
      inicio: evt.rangeselection.startDate,
      final: evt.rangeselection.endDate
    });

    loadDataCB({
      fecha_inicio: rango.inicio,
      fecha_fin: rango.final,
    }, false);
  };

  const selectionRange = {
    startDate: dates.inicio,
    endDate: dates.fin,
    key: 'rangeselection',
  };


  let fechasLookedUp = enabledDates.map(fechaStr => DateHelper.getDate(fechaStr, 'YYYY-MM-DD'));
  fechasLookedUp = fechasLookedUp.sort((a, b) => b - a);

  function isInArray(array, value) {
    const item = array.find(itemChild => DateHelper.getDateFromString(itemChild, 'YYYY-MM-DD')
            === DateHelper.getDateFromString(value, 'YYYY-MM-DD'));
    if (item) return true;
    return false;
  }

  const minFecha = fechasLookedUp && fechasLookedUp.length > 0 && fechasLookedUp[enabledDates.length - 1];
  const maxFecha = fechasLookedUp && fechasLookedUp.length > 0 && fechasLookedUp[0];

  let arrayFechas = DateHelper.getDateArray(('2019-01-01'), maxFecha);
  arrayFechas = arrayFechas.filter(fecha => !isInArray(fechasLookedUp, fecha));

  return (
    <div className={classes.picker}>
      <div
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        style={{ float: 'right', display: 'flex' }}
        aria-owns={openCalendar ? 'simple-popper' : undefined}
        onClick={handleOpenCalendar}
      >
        <TextField
          floatingLabelFixed={true}
          id="outlined-read-only-input"
          label="Fecha de Inicio"
          value={fechas.inicio}
          className={classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            style: { fontSize: 15 }
          }}
          InputLabelProps={{
            shrink: true,
            formlabelclasses: {
              root: classes.labelRoot
            }
          }}
          variant="outlined"
        />
      <div style={{
        justifyContent: 'center',
        placeSelf: 'center'
      }}>
         &nbsp;-&nbsp;
      </div>
        <TextField
          floatingLabelFixed={true}
          id="outlined-read-only-input"
          label="Fecha Final"
          value={fechas.fin}
          className={classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            style: { fontSize: 15 }
          }}
          InputLabelProps={{
            shrink: true,
            formlabelclasses: {
              root: classes.labelRoot
            }
          }}
          variant="outlined"
        />
      </div>

      <Popover
        id="simple-popper"
        open={openCalendar}
        anchorEl={anchorEl}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >

        <DateRangePicker
          maxDate={maxFecha}
          minDate={minFecha}
          ranges={[selectionRange]}
          locale={es}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          onChange={handleDateChange}
          disabledDates={arrayFechas}
          inputRanges={defaultInputRanges}
          staticRanges={defaultStaticRanges}
        />
      </Popover>
    </div>
  );
};

IQDateRangePicker.defaultProps = {
  dates: {
    inicio: new Date(),
    final: new Date(),
  },
  loadDataCB: () => {},
  enabledDates: []
};

IQDateRangePicker.propTypes = {
  classes: PropTypes.shape({
    textField: PropTypes.string,
    labelRoot: PropTypes.string,
    root: PropTypes.string,
    grow: PropTypes.string,
  }).isRequired,
  dates: PropTypes.shape({
    inicio: PropTypes.instanceOf(Date),
    final: PropTypes.instanceOf(Date),
  }),
  enabledDates: PropTypes.shape([]),
  loadDataCB: PropTypes.func
};

export default React.memo(withStyles(styles)(IQDateRangePicker));
