import React, { useState, useContext, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { emphasize, fade, makeStyles } from "@material-ui/core/styles";
import { components } from "../../../../utils";
import { getDBFincas } from "../../../../crud/db.crud";
import DateHelper from '../../../../../helpers/DateHelper';
import DateRangePicker from '../../../../widgets/DateRangePicker';
import es from 'react-date-range/dist/locale';
import * as filterSelector from "../../../../store/ducks/filter.duck";
import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Popover
} from "@material-ui/core";

import { PropTypes } from 'prop-types';

const smallDevice = window.matchMedia('(max-width: 400px)').matches;

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'center',
    display: 'flex',
    // flexDirection: 'column',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  root: {
    flexGrow: 1,
    borderRadius:'4px'
  },
  AppBar: {
    borderRadius:'4px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(1),
    minWidth: 120
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const InputSelect = ({
  name,
  label,
  value,
  children,
  callbackFn,
  classes,
}) => (<div className="form-group">
         <FormControl className={classes.formControl}>
           <InputLabel id={`${name}-label`}>{label}</InputLabel>
           <Select
             name={name}
             labelId={`${name}-label`}
             id={name}
             value={value === 'all' ? value: ( value && Number.parseInt(value) || '' )}
             onChange={(evt) => {
               callbackFn(name, evt.target.value);
             }}
           >
             {children}
           </Select>
         </FormControl>
       </div>)

const Bar = ({
  years,
  weeks,
  fincas,
  groups,
  lotes,
  filters,
  callbackFn
}) => {

  const classes = useStyles();

  const yearsJSX = years.map((item) => <MenuItem value={item}>{ item}</MenuItem>);
  const semanasJSX = weeks.map((item) => <MenuItem value={item}>{ item}</MenuItem>);
  const fincasJSX = fincas.map((item) => <MenuItem value={item.sup_id}>{ item.nombre }</MenuItem>);
  let agrupacionesJSX = groups.map((item) => <MenuItem value={item.id}>{ item.nombre }</MenuItem>);
  agrupacionesJSX = [(<MenuItem value={'all'}>TODOS</MenuItem>) , ...agrupacionesJSX]

  let lotesJSX = lotes.map((item) => <MenuItem value={item.sup_id}>{ item.nombre }</MenuItem>);
  lotesJSX = [(<MenuItem value={'all'}>TODOS</MenuItem>) , ...lotesJSX]

  return (
    <div className="kt-subheader   kt-grid__item" id="kt_subheader">
      <div className="kt-container  kt-container--fluid ">
        <div className={classes.root}>
          <AppBar position="static" color="default"
                  className={classes.AppBar} >
            <Toolbar className={classes.toolbar}>
              <InputSelect
                name="year"
                label="Año"
                value={filters.year}
                callbackFn={callbackFn}
                classes={classes}
              >
                {yearsJSX}
              </InputSelect>
              <InputSelect
                label="Semana"
                name="week"
                value={filters.week}
                callbackFn={callbackFn}
                classes={classes}
              >
                {semanasJSX}
              </InputSelect>
              <InputSelect
                name="finca"
                label="Finca"
                value={filters.finca}
                callbackFn={callbackFn}
                classes={classes}
              >
                {fincasJSX}
              </InputSelect>
              <InputSelect
                name="group"
                label="Agrupación"
                value={filters.group || 'all'}
                callbackFn={callbackFn}
                classes={classes}
              >
                {agrupacionesJSX}
              </InputSelect>
              <InputSelect
                name="lote"
                label="Lote"
                value={filters.lote || 'all'}
                callbackFn={callbackFn}
                classes={classes}
              >
                {lotesJSX}
              </InputSelect>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    </div>
  );
}

Bar.propTypes = {
  weeks: PropTypes.arrayOf(PropTypes.number),
  fincas: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.nombre,
  })),
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.nombre,
  })),
  lotes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.nombre,
  })),
  filters: PropTypes.shape({
    semana: PropTypes.oneOf(PropTypes.number, PropTypes.string),
    finca: PropTypes.number,
    group: PropTypes.number,
    lote: PropTypes.number,
  }),
  callbackFn: PropTypes.func,
};

Bar.defaultProps = {
  /* name: Name of the filter, value: Value of the filter changed */
  callbackFn: (name, value) => {
    console.error('Not yet implemented')
  },
  years: [],
  weeks: [],
  fincas: [],
  groups: [],
  lotes: [],
  filters: {
    year: null,
    semana: null,
    finca: null,
    group: null,
    lote: null
  }
};

export default React.memo(Bar);
