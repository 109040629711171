import React, { useReducer, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { URL_FECHA_INICIAL, fechaInicialLinks } from "../../../../crud/configuracionInicial.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Switch,
  Chip,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import Button from '../shared/Button';
import DatatableMenu from '../shared/DatatableMenu';

import ConfigHeader from '../ConfigHeader'
import LoadingOverlay from 'react-loading-overlay';
import reducer,  * as ducks from './reducer';

import * as constants from './constants';

const {
  list, create, update, remove
} = fechaInicialLinks;

const List = props => {
  const history = useHistory();
const New = ({ onNew }) => (
  <Button onClick={e => onNew()}>Nuevo</Button>
);
  const actionsMemo = React.useMemo(() => [<New onNew={() => {
                                             history.push('/configuration/general/new')
                                           }} />], []);
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name);
      dispatch({type: ducks.SET_DATA, payload: response.data});
    } catch (err) {
      dispatch({type: ducks.SET_DATA, payload: []});
    } finally {
      setLoading(false);
    }
  };

  const removeData = async (id) => {
    setLoading(true);
    try {
      const response = await remove(configSetup.client.technical_name, id);
      dispatch({type: ducks.REMOVE_ITEM, payload: {id}});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (evt, id) => {
    update(configSetup.client.technical_name, id, {activo: evt.target.checked}).then((response) => {
      dispatch({type: ducks.UPDATE, payload: {...response.data}});
    }).catch((err) => {
      console.error(err);
    });
  };


  const columns = [
    {
      name: 'Año',
      selector: 'anio_referencia',
      sortable: true,
    },
    {
      name: 'Inicio',
      selector: 'inicio_anio',
      sortable: true,
    },
    {
      name: 'Fin',
      selector: 'fin_anio',
      sortable: true,
    },
    {
      name: 'Día De Inicio',
      selector: 'calendario.dia_inicio',
      sortable: true,
      cell: row =>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}>
          <Chip label={(row.calendario && constants.dias[row.calendario.dia_inicio])} />
        </div>
    },
    {
      name: 'Semana de Cierre',
      selector: 'calendario.semana_cierre',
      sortable: true,
      cell: row =>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}>
          <Chip label={(row.calendario && constants.semanas[row.calendario.semana_cierre])} />
        </div>
    },
    {
      cell: row => <DatatableMenu
                     justEdit
                     size="small" row={row}
                     onDeleteRow={() => {
                       removeData(row.id);
                     }}
                     onEditRow={() => {
                       history.push(`/configuration/general/edit/${row.id}`)
                     }}
                   />,
      button: true,
      allowOverflow: true,
      width: '56px',
    },
  ];

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/general'}
                name={'General'}
                stage={'Lista'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
<LoadingOverlay
  active={loading}
  spinner
  text='Cargando información'
  >
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Configuraci&oacute;n Inicial
                  </div>
                </div>
                  <div className="kt-portlet__body">
  <DataTable
    actions={actionsMemo}
    title=""
    columns={columns}
    data={state.data}
  />
                  </div>
              </div>
</LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(List);
