import * as crudConfig from "./config.crud";

const { axiosClient, baseURL } = crudConfig;

export const EDAD_PLANTA_URL = `${baseURL}/v1/sigatoka/edad_planta/`;

export const list = (technical_name, finca, year) =>
  axiosClient.get(EDAD_PLANTA_URL, {
    params: { db_tag: technical_name }
  });

export const get = (technical_name, id) =>
  axiosClient.get(`${EDAD_PLANTA_URL}${id}/`, {
    params: { db_tag: technical_name }
  });

export const update = (technical_name, id, data) => axiosClient.patch(`${EDAD_PLANTA_URL}${id}/`, {...data, db_tag: technical_name});
export const create = (technical_name, data) => axiosClient.post(EDAD_PLANTA_URL, { ...data, db_tag: technical_name });
export const remove = (technical_name, id = '') => axiosClient.delete(`${EDAD_PLANTA_URL}${id}/`,  { data: { db_tag: technical_name }});
