import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
  BAJA,
  MODERADA,
  SEVERA,
  umbralesColores,
} from '../../../../../sigat/constants';

const useStyles = makeStyles(theme => ({
  ...umbralesColores,
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 300,
  },
}));

export default function DenseTable({ data }) {
  const classes = useStyles();

  let header = (<React.Fragment />)
  let rows = (<React.Fragment />)

  header = data && data.columns && data.columns.map((value) => {
    return <TableCell key={value}>{value}</TableCell>
  })

  rows = data && data.data && data.data.map((item, i) => {
    const row = data.columns.map((col) => {
      let className = '';
      if(col === 'Valor') {
        const umbral = item['umbral']
        if(umbral === BAJA)  className = classes.umbralBajo
        else if(umbral === MODERADA)  className = classes.umbralModerado
        else if(umbral === SEVERA)  className = classes.umbralSevero
      }
      return <TableCell className={className}>{item[col]}</TableCell>
    })
    return <TableRow key={i}>{row}</TableRow>
  })
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
            {header}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
