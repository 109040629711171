import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, takeEvery, select, call } from "redux-saga/effects";
import * as routerHelpers from "../../router/RouterHelpers";
import {
  getDBDates,
  getDBFincas
} from '../../crud/db.crud';

export const actionTypes = {
  FetchAvailableDates: "[FetchAvailableDates] Action",
  FetchFincas: "[FetchFincas] Action",
  SetFilters: "[SetFilters] Action",
  SetFinca: "[SetFinca] Action",
  SetFincas: "[SetFincas] Action",
  SetDates: "[SetDates] Action",
  SetAvailableDates: "[SetAvailableDates] Action",
};

const initialState = {
  initialDate: null,
  finalDate: null,
  finca: -1, // finca_id
  loading: false,
  /* data available used to create the filters */
  availableDates: [],
  fincas: [],
};

export const getConfigSetup = state => state.configuration;

function* fetchDates(finca) {
  let configuration = yield select(getConfigSetup);
  const dbTag = configuration.client.technical_name;
 try {
  const response = yield getDBDates(dbTag, {
    finca: finca
  });

   yield put({type: actionTypes.SetAvailableDates, payload: { data: response.data } })
 } catch (err) {
   yield put({type: actionTypes.SetAvailableDates, payload: { data: [] } })
 }
};

function* fetchFincas() {
  let configuration = yield select(getConfigSetup);
  const dbTag = configuration.client.technical_name;
 try {
  const response = yield getDBFincas(dbTag, {});

   yield put({type: actionTypes.SetFincas, payload: { data: response.data } })
 } catch (err) {
   yield put({type: actionTypes.SetFincas, payload: { data: [] } })
 }
};


export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FetchAvailableDates: {
        return { ...state, loading: true };
      }

      case actionTypes.FetchFincas: {
        return { ...state, loading: true };
      }

      case actionTypes.SetFilters: {
        const { filters } = action.payload;

        return { ...state, ...filters, loading: false };
      }

      case actionTypes.SetAvailableDates: {
        const { data } = action.payload;

        return { ...state,
                 availableDates: data,
                 loading: false
               };
      }


      case actionTypes.SetDates: {
        const { start, end } = action.payload;

        return { ...state,
                 initialDate: start,
                 finalDate: end,
               };
      }

      case actionTypes.SetFincas: {
        const { data } = action.payload;

        return { ...state,
                 fincas: data,
                 loading: false
               };
      }

      case actionTypes.SetFinca: {
        const { id } = action.payload;

        return { ...state,
                 finca: id,
                 loading: false
               };
      }

      default:
        return state;
    }
  }

export const actions = {
  setFilters: filters => ({ type: actionTypes.SetFilters, payload: { filters } }),
  fetchAvailableDates: (finca) => ({ type: actionTypes.FetchAvailableDates, payload: { finca } }),
  setDates: (start, end) => ({ type: actionTypes.SetDates, payload: { start, end } }),
  fetchFincas: () => ({ type: actionTypes.FetchFincas, payload: { } }),
  setFinca: (id) => ({ type: actionTypes.SetFinca, payload: { id } }),
};

export function* saga() {
  yield takeLatest(actionTypes.FetchFincas, function* fetchFincasSaga() {
    yield call(fetchFincas)
  });

  yield takeLatest(actionTypes.FetchAvailableDates, function* fetchAvailableDatesSaga({ payload }) {
    yield call(fetchDates, payload.finca);
  });
}
