import React, { useReducer, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from '../../IQDatatable';

import { list, update, remove, create } from "../../../../crud/agrupacion.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Switch,
  Chip,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import Button from '../shared/Button';
import DatatableMenu from '../shared/DatatableMenu';

import ConfigHeader from '../ConfigHeader'
import LoadingOverlay from 'react-loading-overlay';
import reducer,  * as ducks from './reducer';

const AgrupacionSuperficieExpanded = ({ data }) => {
  const columns = [
    {
      name: 'Lote',
      selector: 'superficie.nombre',
      sortable: true,
    },
    {
      name: 'Agrupación',
      selector: 'agrupacion.nombre',
      sortable: true,
    },
    {
      name: 'Año',
      selector: 'anio',
      sortable: true,
    },
    {
      name: 'Semana de Inicio',
      selector: 'semana_inicio',
      sortable: true,
    },
    {
      name: 'Semana de Fin',
      selector: 'semana_fin',
      sortable: true,
    },
  ];
  if(data.agrupacion_superficies) {
  return(
    <div className="form-group">
      <DataTable
        title="Agrupaciones realizadas"
        columns={columns}
        data={data.agrupacion_superficies}
      />
    </div>
  );
  } else {
    return null;
  }
}


const List = props => {
  const history = useHistory();
const New = ({ onNew }) => (
  <Button onClick={e => onNew()}>Nuevo</Button>
);
  const actionsMemo = React.useMemo(() => [<New onNew={() => {
                                             history.push('/configuration/agrupacion/new')
                                           }} />], []);
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name);
      dispatch({type: ducks.SET_DATA, payload: response.data});
    } catch (err) {
      dispatch({type: ducks.SET_DATA, payload: []});
    } finally {
      setLoading(false);
    }
  };

  const removeData = async (id) => {
    setLoading(true);
    try {
      const response = await remove(configSetup.client.technical_name, id);
      dispatch({type: ducks.REMOVE_ITEM, payload: {id}});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (evt, id) => {
    update(configSetup.client.technical_name, id, {activo: evt.target.checked}).then((response) => {
      dispatch({type: ducks.UPDATE, payload: {...response.data}});
    }).catch((err) => {
      console.error(err);
    });
  };


  const columns = [
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      name: 'Activo',
      selector: 'activo',
      cell: row => <Switch
        checked={row.activo}
                     onChange={(evt) => {handleChange(evt, row.id)}}
        color="primary"
        name="active"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    },
    {
      cell: row => <DatatableMenu
                     justEdit
                     size="small" row={row}
                     onDeleteRow={() => {
                       removeData(row.id);
                     }}
                     onEditRow={() => {
                       history.push(`/configuration/agrupacion/edit/${row.id}`)
                     }}
                   />,
      button: true,
      allowOverflow: true,
      width: '56px',
    },
  ];

  console.info(state.data)
  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/agrupacion'}
                name={'Agrupaciones'}
                stage={'Lista'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
<LoadingOverlay
  active={loading}
  spinner
  text='Cargando información'
  >
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Agrupaciones
                  </div>
                </div>
                  <div className="kt-portlet__body">
  <DataTable
    actions={actionsMemo}
    title=""
    columns={columns}
    data={state.data}
    expandableRows
    highlightOnHover
    expandableRowsComponent={<AgrupacionSuperficieExpanded />}
  />
                  </div>
              </div>
</LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(List);
