import * as crudConfig from "./config.crud";

const { axiosClient, baseURL } = crudConfig;

export const URL = `${baseURL}/v1/configuracion_inicial/`;

/* METODOLOGIA */

export const URL_METODOLOGIA = `${URL}metodologia/`;

export const metodologiaLinks = {
  list: (technical_name) =>
  axiosClient.get(`${URL_METODOLOGIA}`, {
    params: { db_tag: technical_name }
  }),
  update: (technical_name, id, data) => axiosClient.patch(`${URL_METODOLOGIA}${id}/`, {...data, db_tag: technical_name}),
  create: (technical_name, data) => axiosClient.post(URL_METODOLOGIA, { ...data, db_tag: technical_name }),
  remove: (technical_name, id = '') => axiosClient.delete(`${URL_METODOLOGIA}${id}/`,  { data: { db_tag: technical_name }}),
}


/* Configuracion Fechas */
export const URL_FECHA_INICIAL = `${URL}fecha_inicial/`;

export const fechaInicialLinks = {
  list: (technical_name) =>
  axiosClient.get(`${URL_FECHA_INICIAL}`, {
    params: { db_tag: technical_name }
  }),
  get: (technical_name, id) =>
    axiosClient.get(`${URL_FECHA_INICIAL}${id}/`, {
      params: { db_tag: technical_name }
    }),
  update: (technical_name, id, data) => axiosClient.patch(`${URL_FECHA_INICIAL}${id}/`, {...data, db_tag: technical_name}),
  create: (technical_name, data) => axiosClient.post(URL_FECHA_INICIAL, { ...data, db_tag: technical_name }),
  remove: (technical_name, id = '') => axiosClient.delete(`${URL_FECHA_INICIAL}${id}/`,  { data: { db_tag: technical_name }}),
}
