export const MODULE_ID = 1;
export const NIVELES = {
  1: 'Finca',
  2: 'Lote',
}

export const NIVEL_FINCA = 1;
export const NIVEL_LOTE = 2;

export const choices = [
  {name: 'Preaviso Biologico', id: 1,},
  {name: 'Preaviso Biologico Modificado', id: 2,}
];

export const BAJA = 'baja';
export const MODERADA = 'moderada';
export const SEVERA = 'severa';

export const getMarkerColor = (umbral) => {
  if(umbral === BAJA)
    return `/media/sigat/marker-icon-green.png`;

  else if(umbral === MODERADA)
    return `/media/sigat/marker-icon-yellow.png`;
  else 
    return `/media/sigat/marker-icon-red.png`;
}


export const getIndicatorColor = (umbral) => {
  if(umbral === SEVERA)
    return `#f50057`;
  else if(umbral === MODERADA)
    return `#FFD133`;
  else 
    return `rgb(23, 133, 19)`;
}


export const umbralesColores = {
umbralBajo: {
    color: 'rgb(23, 133, 19)',
  },
  umbralModerado: {
    color: '#FFD133',
  },
  umbralSevero: {
    color: '#f50057',
  }
}
