import React, { useMemo, useState, useContext, useEffect, useReducer, } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { PropTypes } from 'prop-types';
import ConfigHeader from '../shared/ConfigHeader'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import parse, { attributesToProps } from 'html-react-parser';

import {
  create,
} from "../../../../crud/tableau.crud";
import TableauReport from 'tableau-react';

const options = {
  height: 1540,
  width: 1480,
  hideTabs: false,
};
 


const Header = () =>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
	               <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      <ConfigHeader
                        parentHref={'/reportes'}
                        href={'/reportes/tendencia'}
                        name={'Reporte'}
                        stage={'Tendencia'}
                      />
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

const getFormat = (tableauServer, client, token, tipo) => {
  // const script = document.createElement("script");
  
  // script.src = `${tableauServer}javascripts/api/viz_v1.js?token=${token}"`
  // script.async = true;
  // document.body.append(script)
  // console.info('script', script)

  const format = `
  <div class='tableauPlaceholder' style='width: 1420px; height: 4027px;'>
    <object class='tableauViz' width='1420' height='4027' style='display:none;'>
      <param name='host_url' value='${tableauServer}' />
      <param name='embed_code_version' value='3' /> <param name='site_root' value='' />
      <param name='name' value='${client.split('_')[2]}&#47;${tipo}' />
      <param name='tabs' value='no' />
      <param name='toolbar' value='yes' />
      <param name='showAppBanner' value='false' />
      <param name="ticket" value="${token}" />
    </object>
  </div>
  `
    return format
}


const Reporte = ({
  tipoReporte
}) => {
  const [loadToken, setLoader] = useState(true);
  const [token, setToken] = useState('')
  const configSetup = useSelector(state => state.configuration);

  const fetchToken = async () => {
    try {
      // document.getElementsByClassName('tableauPlaceholder')[0].remove()
    } catch (err) {
      console.error(err)
    }
    let response = {};
    try {
      setLoader(true)
      response = await create(configSetup.client.technical_name, {});
    } catch (err) {
      console.error(err)
    } finally {
      setLoader(false)
      console.info(response, response.data)
      try {
        setToken(response.data[0])
      } catch (err) {
        console.error(err)
      } finally {
      }
    }
  };

  /* when the configuration changes we need to fetch a new token */
  useEffect(() => {
      fetchToken()
  }, [configSetup.client.technical_name]);


  const tableau = useMemo(() => {
    console.info(token)
    if(token && configSetup.client.technical_name) {
      return   <TableauReport
                 options={options}
                 url={`https://tableau2.procesosiq.com/views/${configSetup.client.technical_name.split('_')[2]}/COMPARATIVOTENDENCIA`}
                 token={token}
                 query={`?:embed=y&:showVizHome=no&:host_url=https://tableau2.procesosiq.com/&:embed_code_version=3&:tabs=no&:toolbar=yes&:showAppBanner=false&:display_spinner=no&:loadOrderID=0`} 
                 /* query={`?:tabs=no&:embed_code_version=3&:site_root=&:embed=yes&:comments=no&:toolbar=yes&:refresh=yes&:name=${configSetup.client.technical_name.split('_')[2]}&#47;${'COMPARATIVOTENDENCIA'}`} */
               />

    }
    return <React.Fragment />
  }, [configSetup.client.technical_name, token])

  return (
    <React.Fragment>
      <Header />
      <div className="row" style={{}}>
        <div className="col-md-12" style={{
          paddingTop: '30px'
        }}>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-portlet">
                  <div className="kt-portlet__body">
    <div className="row" style={{}}>
      { tableau }
    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Reporte.propTypes = {
  name: PropTypes.string,
  age: PropTypes.number
};

export default React.memo(Reporte);
