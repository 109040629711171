import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as filter from "./ducks/filter.duck";
import * as reportFilter from "./ducks/reportFilter.duck";
import * as configuration from "./ducks/configuration.duck";
import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  filter: filter.reducer, /* Used in DB */
  reportFilter: reportFilter.reducer, /* Used in reports */
  configuration: configuration.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    configuration.saga(),
    filter.saga(),
    reportFilter.saga(),
  ]);
}
