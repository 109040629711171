import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import * as routerHelpers from "../../router/RouterHelpers";
import { actionTypes as authActionTypes } from './auth.duck';

export const actionTypes = {
  SetClient: "[SetClient] Action",
  SetClients: "[SetClients] Action",
  SetConfiguration: "[SetConfiguration] Action",
  UserLoaded: "[Load User] Auth API",
  UserRequested: "[Request User] Action",
  SetTableauSite: "[SetTableauSite] Action",
};

const initialState = {
  client: undefined,
  clients: [],
  configuration: undefined,
  tableauSite: '',
};

export const getAuth = state => state.auth;

export const reducer = persistReducer(
  { storage, key: "sigat-config", whitelist: ["client"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetClient: {
        const { client } = action.payload;
        return { ...state, client };
      }

      case actionTypes.SetClients: {
        const { clients } = action.payload;
        const  {client: previousClient } = state;
        if(previousClient) {
          const clientMatched = clients.find((item) => {
            return JSON.stringify(item) === JSON.stringify(previousClient);
          });
          if(clientMatched) {
            return { clients, client: clientMatched };
          }
        }
        return { clients, client: ( clients && clients.length > 0 && clients[0] ) || undefined };
      }

      case actionTypes.SetTableauSite: {
        const { tableauSite } = action.payload;
        return {...state, tableauSite};
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setClient: client => ({ type: actionTypes.SetClient, payload: { client } }),
  setClients: clients => ({
    type: actionTypes.SetClients,
    payload: { clients }
  }),
  setTableauSite: tableauSite => ({
    type: actionTypes.SetTableauSite,
    payload: { tableauSite }
  }),
};

export function* saga() {
  yield takeLatest(authActionTypes.Logout, function* logoutClientRemover() {
    yield put(actions.setClient(undefined));
  });
}
