import * as crudConfig from "./config.crud";
const { axiosClient, baseURL } = crudConfig;

export const GET_LEAF_EMISSIONS_URL = `${baseURL}/v1/sigatoka/revision-emision-foliar/`;
export const UPDATE_LEAF_EMISSIONS_URL = `${baseURL}/v1/sigatoka/actualizacion-emision-foliar/`;
export const FARMS_URL = `${baseURL}/v1/sigatoka/filter/fincas-foliar/`;

export const getLeafEmissions = (technical_name, year, finca) =>
  axiosClient.get(GET_LEAF_EMISSIONS_URL, {
    params: { db_tag: technical_name, year, finca }
  });

export const getFarmsLeafEmissions = technical_name =>
  axiosClient.get(FARMS_URL, {
    params: { db_tag: technical_name }
  });

export const changeLeafEmissionValue = (technical_name, emission_id, value) => {
  return axiosClient.post(UPDATE_LEAF_EMISSIONS_URL,
    { value, emission_id, },
    { params: { db_tag: technical_name }
  });
};
