import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid
} from "@material-ui/core";

import PropTypes from 'prop-types';

import Indicador from './Indicator';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
}));

const Indicadores = ({
  indicadores, cbFunc, variables
}) => {

  const classes = useStyles();

  const items = []
  Object.keys(indicadores).forEach((it) => {
    items.push(...indicadores[it])
  })

  const indicadoresJSX = items.map((item) => {
    let valor = 0;
    const subValue = null;
    const status = 'green-jungle';
    const classIcon = 'fa-pie-chart';
    const nombre = null;

      return (
          <Indicador
            name={`${item.nombre_edad}`}
            subtitle={`${item.nombre_variablemedicion}`}
            peso={item.promedio || valor}
            umbral={item.umbral}
          />
      );
  });

  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <Grid container className={classes.root} spacing={2}>
        { indicadoresJSX }
      </Grid>
    </div>
  );
};

Indicadores.defaultProps = {
  indicadores:{},
  cbFunc: () => {}
};

Indicadores.propTypes = {
  indicadores: PropTypes.shape({
    rv: PropTypes.arrayOf(PropTypes.shape({
      promedio: PropTypes.number,
    })),
    mc: PropTypes.arrayOf(PropTypes.shape({
      promedio: PropTypes.number,
    })),
  }),
  cbFunc: PropTypes.func,
};

export default React.memo(Indicadores);
