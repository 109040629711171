import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import * as configurationConstants from '../../../../../helpers/ConfigurationConstants';
import UmbralNew from '../umbral/umbralNew'
import VariableMedicionNew from '../variable_medicion/variableMedicionesNew'

import Actions from '../shared/CreateActions';
import Button from '../shared/Button';
import { TextField } from "@material-ui/core";
import { get, list, update, remove, create } from "../../../../crud/edadPlanta.crud";
import { list as listVariableMedicion } from "../../../../crud/variableMedicion.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper
} from "@material-ui/core";

import Draggable from "react-draggable";

import ConfigHeader from '../ConfigHeader'
import reducer,  * as edadPlantasDucks from './reducer';

// eslint-disable-next-line
const InformationUmbral = ({ data }) => {
  const { COLORES } = configurationConstants;
  const rangoColumns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Color',
      selector: 'color',
      sortable: true,
      cell: row => 
        <div>
          { COLORES.find((item) => {
            return item.num === row.color
          }).value }
        </div>
    },
    {
      name: 'Minimo',
      selector: 'min',
      sortable: true,
    },
    {
      name: 'Maximo',
      selector: 'max',
      sortable: true,
    },
    ]
  return (
    <div className="form-group">
      <DataTable
        columns={rangoColumns}
        data={data.rango_umbrales}
      />
    </div>
);
}


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none"
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const Edit = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMedicion, setOpenMedicion] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const NewUmbralBtn = ({ onNew }) => (
    <Button onClick={e => { e.preventDefault(); onNew() }}>Nuevo</Button>
  );

  const actionsMemo = React.useMemo(() => [<NewUmbralBtn  onNew={() => {
    setOpen(true);
  }} />], []);

  const actionsMemoMediciones = React.useMemo(() => [<NewUmbralBtn  onNew={() => {
    setOpenMedicion(true);
  }} />], []);

  const [initialForm, setInitialForm] = useState({abreviatura: '', nombre: '', activo: null, umbrales: [], variables: []})

  const handleClose = () => {
    setOpen(false);
    setOpenMedicion(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    setOpenMedicion(false);
  };

  const onUmbralCreated = () => {
    setOpen(false);
    setOpenMedicion(false);
    fetchData();
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, edadPlantasDucks.initialState)
   const configSetup = useSelector(state => state.configuration);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await get(configSetup.client.technical_name, props.match.params.id);
      setInitialForm(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  const varColumns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      name: 'Abreviatura',
      selector: 'abreviatura',
      sortable: true,
    },
    ]

  const umbralColumns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },

    {
      name: 'Tipo',
      selector: 'tipo_umbral',
      sortable: true,
      cell: row => 
                  <TextField
                    type="text"
                    margin="normal"
                    className="kt-width-shrink"
                    name="tipo_umbral"
                    value={configurationConstants.TIPOS_UMBRAL[row.tipo_umbral]}
                  />
    },
    ]

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/edad_planta'}
                name={'Edad Plantas'}
                stage={'Edición'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Edici&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              nombre: initialForm.nombre,
              abreviatura: initialForm.abreviatura,
              activo: initialForm.activo 
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 

              if (!values.abreviatura) {
                errors.abreviatura = 'Ingrese una abreviatura';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                update(configSetup.client.technical_name, initialForm.id,
                       {abreviatura: values.abreviatura,
                        nombre: values.nombre,
                        activo: values.activo})
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/edad_planta/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando edad de planta');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Abreviatura"
                    margin="normal"
                    className="kt-width-full"
                    name="abreviatura"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.abreviatura}
                    helperText={touched.abreviatura && errors.abreviatura}
                    error={Boolean(touched.abreviatura && errors.abreviatura)}
                  />
                </div>

                <div className="form-group">
                  <DataTable
                    actions={actionsMemoMediciones}
                    title="Variables de Medicion"
                    columns={varColumns}
                    data={initialForm.variables}
                  />
                </div>

                <div className="form-group">
                  <DataTable
                    actions={actionsMemo}
                    title="Umbrales"
                    columns={umbralColumns}
                    data={initialForm.umbrales}
                    expandableRows
                    expandableRowsComponent={<InformationUmbral />}
                    expandOnRowClicked
                  />
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/edad_planta/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Umbral Nuevo
        </DialogTitle>
        <DialogContent>
          <UmbralNew
            isDialog
            edadPlantaId={initialForm.id}
            onUmbralCreated={onUmbralCreated}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose} color="primary"> */}
          {/*   Cancelar */}
          {/* </Button> */}
          {/* <Button onClick={handleSubmit} color="primary"> */}
          {/*   Aceptar */}
          {/* </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openMedicion}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Variable de medici&oacute;n nueva
        </DialogTitle>
        <DialogContent>
          <VariableMedicionNew
            isDialog
            edadPlantaId={initialForm.id}
            onCreated={onUmbralCreated}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose} color="primary"> */}
          {/*   Cancelar */}
          {/* </Button> */}
          {/* <Button onClick={handleSubmit} color="primary"> */}
          {/*   Aceptar */}
          {/* </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default React.memo(Edit);
