import * as crudConfig from "./config.crud";

const { axiosClient, baseURL } = crudConfig;

export const REPORT_YEARS_URL = `${baseURL}/v1/sigatoka/filter/years/`;
export const REPORT_WEEKS_URL = `${baseURL}/v1/sigatoka/filter/weeks/`;
export const REPORT_FINCAS_URL = `${baseURL}/v1/sigatoka/filter/fincas/`;
export const REPORT_GROUPS_URL = `${baseURL}/v1/sigatoka/filter/agrupaciones/`;
export const REPORT_LOTES_URL = `${baseURL}/v1/sigatoka/filter/lotes/`;


export const getReportYears = (technical_name, params) =>
  axiosClient.get(REPORT_YEARS_URL, {
    params: { db_tag: technical_name, ...params}
  });

export const getReportWeeks = (technical_name, params) =>
  axiosClient.get(REPORT_WEEKS_URL, {
    params: { db_tag: technical_name, ...params}
  });

export const getReportFincas = (technical_name, params) =>
  axiosClient.get(REPORT_FINCAS_URL, {
    params: { db_tag: technical_name, ...params}
  });

export const getReportGroups = (technical_name, params) =>
  axiosClient.get(REPORT_GROUPS_URL, {
    params: { db_tag: technical_name, ...params}
  });

export const getReportLotes = (technical_name, params) =>
  axiosClient.get(REPORT_LOTES_URL, {
    params: { db_tag: technical_name, ...params}
  });
