import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  GridList,
  GridListTile,
  GridListTileBar,
  ListSubheader,
  Chip
} from "@material-ui/core";

import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    height: "100%",
    width: "100%"
  }
}));

const Gallery = ({ data: info }) => {
  const classes = useStyles();

  const [filter, setFilter] = useState(null);
  let infoFotos = info.data;
  if (filter) {
    infoFotos = info.data.filter(item => {
      return parseInt(item.labor_id) === parseInt(filter);
    });
  }

  let fotos = infoFotos.map(({ imagen, comentario }, index) => {
    return (
      <GridListTile key={index}>
        <img
          src={imagen}
          alt={comentario}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "scale-down"
          }}
        />
        <GridListTileBar subtitle={<span>{comentario}</span>} />
      </GridListTile>
    );
  });

  const availableFilters = [
    ...new Map(info.data.map(item => [item["labor_id"], item])).values()
  ];

  return (
    <div
      className="col-md-12"
      style={{
        margin: "10px 20px 0px 20px"
      }}
    >
      <div className={classes.root}>
        <GridList
          cellHeight={300}
          cols={4}
          spacing={2}
          className={classes.gridList}
        >
          <FilterBar
            availableFilters={availableFilters}
            selected={filter}
            onChange={setFilter}
          />
          <GridListTile key="Subheader" cols={4} style={{ height: "10px" }}>
            <ListSubheader component="div"></ListSubheader>
          </GridListTile>
          {(info.data.length > 0 && fotos) || (
            <GridListTile key="Subheader" cols={1} style={{ height: "auto" }}>
              <ListSubheader component="div">No hay fotos.</ListSubheader>
            </GridListTile>
          )}
        </GridList>
      </div>
    </div>
  );
};

const useStylesBar = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },

  root2: {
    backgroundColor: "rgb(23, 133, 19)",
    color: "white"
  }
}));

const FilterBar = ({ availableFilters, selected, onChange }) => {
  const classes = useStylesBar();

  const handleClick = id => {
    onChange(id);
  };

  const todosChip =
    selected === null ? (
      <Chip className={classes.root2} label="TODOS" clickable />
    ) : (
      <Chip
        label="TODOS"
        onClick={() => {
          handleClick(null);
        }}
        clickable
      />
    );

  let chips = [todosChip];

  const filterChips = availableFilters.map(({ labor_id, labor_nombre }) => {
    if (labor_id === selected) {
      return <Chip className={classes.root2} label={labor_nombre} clickable />;
    } else {
      return (
        <Chip
          label={labor_nombre}
          clickable
          onClick={() => {
            handleClick(labor_id);
          }}
        />
      );
    }
  });

  chips = [...chips, ...filterChips];

  return <div className={classes.root}>{chips}</div>;
};

Gallery.defaultProps = {
  data: { data: [] }
};

Gallery.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        labor_id: PropTypes.number,
        comentario: PropTypes.string,
        imagen: PropTypes.string
      })
    )
  })
};

export default React.memo(Gallery);
