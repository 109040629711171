import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Diario } from "./reportes/diario";
import { Comparativo } from "./reportes/comparativo";
import { Tendencia } from "./reportes/tendencia";
import { TableauComparativo } from "./reportes/comparativo";
import { TableauTendencia } from "./reportes/tendencia";
import Tableau from "./reportes/shared/Tableau";
import { ErrorPage3 } from '../errors/ErrorPage3'

const Reportes = props => {
  return (
      <Switch>
        {<Redirect exact from="/reportes" to="/reportes/diario" />}
        <Route exact path="/reportes/diario" component={Diario} />
        <Route exact path="/reportes/comparativo" 
               render={(props) => (
                 <Tableau {...props} tipoReporte={'COMPARATIVOSEMANA'} breadcrumb={'comparativo'}/>
               )} />
        <Route exact path="/reportes/tendencia" 
               render={(props) => (
                 <Tableau {...props} tipoReporte={'COMPARATIVOTENDENCIA'} breadcrumb={'tendencia'}/>
               )} />
        <Route exact path="/reportes/tabla-relacion" 
               render={(props) => (
                 <Tableau {...props} tipoReporte={'Tablarelacion'} breadcrumb={'tabla-relacion'} wbnumber={'2'}/>
               )} />
        <Route exact path="/reportes/relacion" 
               render={(props) => (
                 <Tableau {...props} tipoReporte={'Reportederelacion'} breadcrumb={'relacion'} wbnumber={'2'}/>
               )} />
        <Route component={ErrorPage3}/>
      </Switch>
  )
}

export default Reportes;
