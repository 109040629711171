import React, {useEffect, useMemo, useCallback, } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


function Checkboxes({ check, setCheck }) {

  const handleChange = useCallback((evt) => {
    const name = evt.target.name
    const value = evt.target.checked
    if(name === 'todos' && value === true) {
      setCheck({ ...check, todos:true, labores: true, origen: true, destino: true, muestras:true })
    } else {
      setCheck({...check, [name]: value, todos: false})
    }
  }, [check, setCheck])
  return (
 <FormGroup row>
      <FormControlLabel
        control={<Checkbox checked={check.todos} onChange={handleChange} name="todos" />}
        label="TODOS"
      />

      <FormControlLabel
        control={<Checkbox checked={check.labores} onChange={handleChange} name="labores" />}
        label="LABORES"
      />
      <FormControlLabel
        control={<Checkbox checked={check.origen} onChange={handleChange} name="origen" />}
        label="ORIGEN"
      />

      <FormControlLabel
        control={<Checkbox checked={check.destino} onChange={handleChange} name="destino" />}
        label="DESTINO"
      />

      <FormControlLabel
        control={<Checkbox checked={check.muestras} onChange={handleChange} name="muestras" />}
        label="MUESTRAS"
      />
 </FormGroup>
  );
}

export default React.memo(Checkboxes);
