import React, { useState, useContext, useEffect } from "react";
import { PropTypes } from 'prop-types';

import ResumenVariableHojas from './ResumenVariableHojas';
import ResumenEE from './ResumenEstadoEvolutivo'
const Resumen = ({
  rv, mc
}) => {
  return (
    <>
    <div className="col-md-12">
      <ResumenEE data={mc} />
    </div>
    <div className="col-md-12">
      <ResumenVariableHojas data={rv} />
    </div>
    </>
  );
}

Resumen.propTypes = {
};

export default React.memo(Resumen);
