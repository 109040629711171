import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import Actions from '../shared/CreateActions';
import { TextField } from "@material-ui/core";
import { list, update, remove, create } from "../../../../crud/edadPlanta.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import reducer,  * as edadPlantasDucks from './reducer';
import ConfigHeader from '../ConfigHeader'

const New = props => {

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, edadPlantasDucks.initialState)
   const configSetup = useSelector(state => state.configuration);

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name);
      dispatch({type: edadPlantasDucks.SET_EDAD_PLANTAS, payload: response.data});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/edad_planta'}
                name={'Edad Plantas'}
                stage={'Nuevo'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Nueva Edad de Planta
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            initialValues={{
              nombre: "",
              abreviatura: "",
              activo: true 
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.nombre = 'Ingrese un nombre';
              } 

              if (!values.abreviatura) {
                errors.abreviatura = 'Ingrese una abreviatura';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                create(configSetup.client.technical_name, {nombre: values.nombre, abreviatura: values.abreviatura, activo: values.activo})
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/edad_planta/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando edad de planta');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Abreviatura"
                    margin="normal"
                    className="kt-width-full"
                    name="abreviatura"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.abreviatura}
                    helperText={touched.abreviatura && errors.abreviatura}
                    error={Boolean(touched.abreviatura && errors.abreviatura)}
                  />
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/edad_planta/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(New);
