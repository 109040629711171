import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { MenuItem, TextField, FormControl, InputLabel, Select  } from "@material-ui/core";
import { Formik, } from "formik";
import Actions from '../shared/CreateActions';
import { NIVEL_LOTE, NIVEL_FINCA, NIVELES } from '../../../../../sigat/constants';
import {
  years, weeks,
} from '../DateUtils';

import { get, list, update, remove, } from "../../../../crud/superficie.crud";
import { create, } from "../../../../crud/agrupacion_superficie.crud";

import { list as listAgrupaciones} from "../../../../crud/agrupacion.crud";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(1),
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AgrupacionSuperficieDialog({
  padre, // finca Padre
  cb, // once it is created we reset the data or get some data
  createDialog, // flag edit/create
  
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [superficies, setSuperficies] = useState([]);
  const [supSelected, setSupSelected] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const configSetup = useSelector(state => state.configuration);
  const [initialForm, setInitialForm] = useState({
    superficie: null,
    agrupacion: null,
    semana_inicio: null,
    semana_fin: null,
    anio: null,
  })

  const fetchSuperficies = async () => {
    try {
      const response = await list(configSetup.client.technical_name, {nivel: NIVEL_LOTE});
      setSuperficies(response.data)
      setSupSelected((response.data && response.data.length> 0 && response.data[0].id) || null)
    } catch (err) {
      console.error(err)
    } finally {}
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchSuperficies();
    }
  }, [configSetup.client]);

  const superficiesJSX = superficies.map((item) => {
    return (<MenuItem value={item.id}>{`Finca: ${item && item.finca && item.finca.nombre} Lote: ${item.nombre}`}</MenuItem>)
  })

  const yearsJSX = years.map((item) => {
    return (<MenuItem value={item}>{item}</MenuItem>)
  })

  const weeksJSX = weeks.map((item) => {
    return (<MenuItem value={item}>{item}</MenuItem>)
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Nueva Agrupaci&oacute;n de Superficie
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
      Agregando superficie a agrupaci&oacute;n {padre && padre.nombre}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{
          margin: '0 0 0 20px'
        }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              superficie: initialForm.superficie,
              agrupacion: initialForm.agrupacion,
              semana_inicio: initialForm.semana_inicio,
              semana_fin: initialForm.semana_fin,
              anio: initialForm.anio,
            }}
            validate={values => {
              const errors = {};

              if (!values.superficie) {
                errors.superficie = 'Seleccione una superficie';
              } 

              if (!values.semana_inicio) {
                errors.agrupacion = 'Seleccione una semana de inicio';
              } 

              if (!values.semana_fin) {
                errors.agrupacion = 'Seleccione una semana de finalización';
              } 

              if (!values.anio) {
                errors.agrupacion = 'Seleccione un año';
              } 

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              if(createDialog) {
                cb({
                  superficie: values.superficie,
                  semana_inicio: values.semana_inicio,
                  semana_fin: values.semana_fin,
                  anio: values.anio,
                })
                setOpen(false);
              } else {
              setTimeout(() => {
                create(configSetup.client.technical_name, {
                  superficie: values.superficie,
                  semana_inicio: values.semana_inicio,
                  semana_fin: values.semana_fin,
                  anio: values.anio,
                  agrupacion: padre.id,
                })
                  .then(() => {
                    disableLoading();
                    cb();
                    setOpen(false);
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error editando superficie');
                  });
              }, 1000);
              }
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                <label for="agrupacion">Agrupaci&oacute;n: </label>
            <Typography variant="h7" className={classes.title} id="agrupacion">
              {(padre && padre.nombre) || ''}
            </Typography>
                </div>
                <div className="form-group">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label">Superficie</InputLabel>
                        <Select
                          name="superficie"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.superficie}
                          onChange={handleChange}
                        >
                          {superficiesJSX}
                        </Select>
                      </FormControl>
                </div>
                <div className="form-group">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label">Año</InputLabel>
                        <Select
                          name="anio"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.anio}
                          onChange={handleChange}
                        >
                          {yearsJSX}
                        </Select>
                      </FormControl>
                </div>
                <div className="form-group">
                  <FormControl className={classes.formControl}>
                    <TextField 
                      name="semana_inicio"
                      label="Semana de Inicio"
                      id="semana_inicio_txt"
                      type="number"
                      value={values.semana_inicio}
                      onChange={handleChange}
                      inputProps= {{ max: 53, min: 1 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField 
                      name="semana_fin"
                      label="Semana Final"
                      id="semana_inicio_txt"
                      type="number"
                      value={values.semana_fin}
                      onChange={handleChange}
                      InputProps= {{ max: 53, min: 1 }}
                    />
                  </FormControl>
                </div>
                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                  Crear
                </button>
              </form>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}
