import React, { useReducer, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from '../../IQDatatable';

import { list, update, remove, create } from "../../../../crud/empleado.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Switch,
  Chip,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import Button from '../shared/Button';
import DatatableMenu from '../shared/DatatableMenu';
import { NIVELES, NIVEL_FINCA, NIVEL_LOTE } from '../../../../../sigat/constants';
import ConfigHeader from '../ConfigHeader'
import LoadingOverlay from 'react-loading-overlay';
import reducer,  * as ducks from './reducer';

const SuperficiesExpanded = ({ data }) => {
  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      name: 'Hectáreas',
      selector: 'hectarea',
      sortable: true,
    },
    {
      name: 'Agrupación',
      selector: 'agrupacion',
      sortable: true,
    },
  ];
  if(data.superficies) {
  return(
    <div className="form-group">
      <DataTable
        title="Fincas"
        columns={columns}
        data={data.superficies}
      />
    </div>
  );
  } else {
    return null;
  }
}

const List = props => {
  const history = useHistory();
  const New = ({ onNew }) => (
    <Button onClick={e => onNew()}>Nuevo</Button>
  );
  const [loading, setLoading] = useState(true);
  const [nivel, setNivel] = useState(NIVEL_FINCA);
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  const actionsMemo = React.useMemo(() => [
    <New onNew={() => {history.push('/configuration/supervisor/new')}} />,], []);

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client, nivel]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name, {is_supervisor: true});
      dispatch({type: ducks.SET_DATA, payload: response.data});
    } catch (err) {
      dispatch({type: ducks.SET_DATA, payload: []});
    } finally {
      setLoading(false);
    }
  };

  const removeData = async (id) => {
    setLoading(true);
    try {
      const response = await remove(configSetup.client.technical_name, id);
      dispatch({type: ducks.REMOVE_ITEM, payload: {id}});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (evt, id) => {
    update(configSetup.client.technical_name, id, {activo: evt.target.checked}).then((response) => {
      dispatch({type: ducks.UPDATE, payload: {...response.data}});
    }).catch((err) => {
      console.error(err);
    });
  };


  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'persona.nombre',
      sortable: true,
    },
    {
      name: 'Cargo',
      selector: 'cargo.nombre',
      sortable: true,
    },
    {
      name: 'Departamento',
      selector: 'cargo.departamento.nombre',
      sortable: true,
    },
    {
      cell: row =>
        <DatatableMenu
          justEdit
          size="small" row={row}
          onDeleteRow={() => {
            removeData(row.id);
          }}
          onEditRow={() => {
            history.push(`/configuration/supervisor/edit/${row.id}`)
          }}
        >
        </DatatableMenu>,
      button: true,
      allowOverflow: true,
      width: '56px',
    },
  ];

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/supervisor'}
                name={'Supervisores'}
                stage={'Lista'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
<LoadingOverlay
  active={loading}
  spinner
  text='Cargando información'
  >
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Supervisores
                  </div>
                </div>
                  <div className="kt-portlet__body">
  <DataTable
    expandableRows
    highlightOnHover
    actions={actionsMemo}
    title=""
    columns={columns}
    data={state.data}
    expandableRowsComponent={<SuperficiesExpanded />}
  />
                  </div>
              </div>
</LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(List);
