import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PropTypes } from 'prop-types';

const Actions = ({
  route,
  className,
  isSubmitting,
  style,
}) => {

  const history = useHistory();

  return (
    <div className="kt-login__actions">
      <button
        id="kt_login_signin_submit"
        type="submit"
        disabled={isSubmitting}
        className={className}
        style={{...style, float: 'right' }}
      >
        Guardar
      </button>

      <button
        onClick={() => {
          history.push(route)
        }}
        id="kt_login_signin_submit"
        type="submit"
        className={`btn btn-primary btn-elevate kt-login__btn-primary`}
        style={{float: 'left'}}
      >
        Atr&aacute;s
      </button>
    </div>
  );
}

export default React.memo(Actions);
