import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { MenuItem, TextField, FormControl, InputLabel, Select  } from "@material-ui/core";
import { Formik, } from "formik";
import Actions from '../shared/CreateActions';
import DepartmentAutoSuggest from './DepartmentAutoSuggest'

import { get, list, update, remove, create } from "../../../../crud/cargo.crud";
import * as departamentoCrud from "../../../../crud/departamento.crud";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateDialog({
  createCB, // once it is created we reset the data or get some data
  createMode, // flag edit/create
  
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [departamentos, setDepartamentos] = useState([]);
  const [departamentoSelected, setDepartamentoSelected] = useState(null);
  const [departamentoSuggestion, setDepartamentoSuggestion] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const configSetup = useSelector(state => state.configuration);
  const [initialForm, setInitialForm] = useState({
    nombre: 'Supervisor',
    departamento: null,
  })

  const fetchDepartamentos = async () => {
    try {
      const response = await departamentoCrud.list(configSetup.client.technical_name);
      setDepartamentos(response.data)
      setDepartamentoSelected((response.data && response.data.length> 0 && response.data[0].id) || null)
    } catch (err) {
      console.error(err)
    } finally {}
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchDepartamentos();
    }
  }, [configSetup.client]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Nuevo Cargo/Departamento
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Creación de Cargo
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{
          margin: '0 0 0 20px'
        }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              nombre: initialForm.nombre,
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();

              if(!departamentoSuggestion || (departamentoSuggestion && !departamentoSuggestion.nombre)) {
                alert('No hay departamento seleccionado o creado')
                return;
              }
              if(createMode) {
                const dep = departamentos.find((item) => {
                  return item.nombre.toLowerCase() === departamentoSuggestion
                    && departamentoSuggestion.nombre.toLowerCase()
                })

              setTimeout(() => {
                create(configSetup.client.technical_name, {
                  nombre: values.nombre,
                  departamento: dep || departamentoSuggestion,
                })
                  .then(() => {
                    disableLoading();
                    createCB();
                    setOpen(false);
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando departamento');
                  });
              }, 1000);

                setOpen(false);
              } 
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    disabled
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>
                <div className="form-group">
                  <FormControl className={classes.formControl}>
                    <DepartmentAutoSuggest
                      value={departamentoSuggestion}
                      setValue={setDepartamentoSuggestion}
                      items={departamentos}
                    />
                  </FormControl>
              </div>
                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                  Crear
                </button>
              </form>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}
