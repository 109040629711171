import React, { Component, useMemo, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { emphasize, fade, makeStyles } from "@material-ui/core/styles";

import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Popover
} from "@material-ui/core";

import { choices } from '../../../../../sigat/constants'

const smallDevice = window.matchMedia('(max-width: 400px)').matches;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    borderRadius:'4px'
  },
  AppBar: {
    borderRadius:'4px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Bar = ({
  metodo, onChangeMetodo, fetchMetodologia
}) => {
  const classes = useStyles();
  const configSetup = useSelector(state => state.configuration);

  const choicesJSX = choices.map((item) => {
    return <MenuItem value={item.id}>{ item.name }</MenuItem>
  })

  useEffect(() => {
    const { client } = configSetup;
    if (client) {}
  }, [configSetup, configSetup.client]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default"
              className={classes.AppBar} >
        <Toolbar>
          <div className={classes.search}>
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink={true}
                id="finca_label">Tipo de Método</InputLabel>
              <Select
                name="tipo_metodo"
                labelId="finca_label"
                id="finca"
                value={metodo && metodo.nombre && metodo.nombre}
                onChange={(evt) => {
                  onChangeMetodo(evt.target.value)
                }}
              >
                {choicesJSX}
              </Select>
            </FormControl>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default React.memo(Bar);
