export const SET_INDICADORES = 'SET_INDICADORES';
export const SET_FOTOS = 'SET_FOTOS';
export const SET_MAPA = 'SET_MAPA';
export const SET_MAPA_LABORES = 'SET_MAPA_LABORES';
export const SET_MAPA_EVALUACIONES = 'SET_MAPA_EVALUACIONES';
export const SET_RESUMEN_RV = 'SET_RESUMEN_RV';
export const SET_RESUMEN_RC = 'SET_RESUMEN_RC';

export const LOAD_RESUMEN_RC = 'LOAD_RESUMEN_RC';
export const LOAD_RESUMEN_RV = 'LOAD_RESUMEN_RV';
export const LOAD_FOTOS = 'LOAD_FOTOS';
export const LOAD_MAPA = 'LOAD_MAPA';
export const LOAD_MAPA_LABORES = 'LOAD_MAPA_LABORES';
export const LOAD_MAPA_EVALUACIONES = 'LOAD_MAPA_EVALUACIONES';
export const LOAD_INDICADORES = 'LOAD_INDICADORES';
export const SET_CHECK = 'SET_CHECK';

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const getRandomSign = () => Math.random() > 0.5 ? 1 : -1;

export const initialState = {
  indicadores: {},
  fotos: {data: []},
  mapa: [],
  mapaLabores: [],
  mapaEvaluaciones: { data_geo: [], data_geo_enviada: [] },
  resumenRV: [],
  resumenRC: [],

  /* loaders */
  loadIndicadores: false,
  loadFotos: false,
  loadMapa: false,
  loadMapaLabores: false,
  loadMapaEvaluaciones: false,
  loadResumenRV: false,
  loadResumenRC: false,
  check: {
    todos: true,
    labores: false,
    origen: false,
    destino: false,
    muestras: false,
  }
}

export default function reducer(state, action) {
  switch (action.type) {
  case SET_CHECK:
    return {
      ...state,
      check: {...action.payload}
    }
    case LOAD_INDICADORES:
      return {
        ...state,
        loadIndicadores: true,
      };
    case LOAD_RESUMEN_RV:
      return {
        ...state,
        loadResumenRV: true,
      };
    case LOAD_RESUMEN_RC:
      return {
        ...state,
        loadResumenRC: true,
      };
    case LOAD_FOTOS:
      return {
        ...state,
        loadFotos: true,
      };
    case LOAD_MAPA:
      return {
        ...state,
        loadMapa: true,
      };
    case LOAD_MAPA_LABORES:
      return {
        ...state,
        loadMapaLabores: true,
      };

    case LOAD_MAPA_EVALUACIONES:
      return {
        ...state,
        loadMapaEvaluaciones: true,
      };

    case SET_INDICADORES:
      return {
        ...state,
        indicadores: action.payload,
        loadIndicadores: false,
      };

    case SET_FOTOS:
      return {
        ...state,
        fotos: action.payload,
        loadFotos: false,
      };

    case SET_MAPA:
      const R = 6378137;  
      const c = 180 / Math.PI; 
      if (action.payload.data) {
        action.payload.data.forEach(e => {
          let dn = getRandomSign() * (getRandomInt(1, 15));
          let de = getRandomSign() * (getRandomInt(1, 15));
          let dLat = dn / R;
          let dLon = (de / R) / Math.cos(e.data[0].latitud * Math.PI / 180);
          e.data[0].latitud = e.data[0].latitud + dLat * c;
          e.data[0].longitud = e.data[0].longitud + dLon * c;
        });
      }
      return {
        ...state,
        mapa: action.payload,
        loadMapa: false,
      };
    case SET_MAPA_LABORES:
      return {
        ...state,
        mapaLabores: action.payload,
        loadMapaLabores: false,
      };

    case SET_MAPA_EVALUACIONES:
      return {
        ...state,
        mapaEvaluaciones: {...action.payload},
        loadMapaEvaluaciones: false,
      };

    case SET_RESUMEN_RC:
      return {
        ...state,
        resumenRC: action.payload,
        loadResumenRC: false,
      };

    case SET_RESUMEN_RV:
      return {
        ...state,
        resumenRV: action.payload,
        loadResumenRV: false,
      };

    default:
      return state;
  }
}
