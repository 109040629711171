import React, { useState, useEffect, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DB from "./DB";
import Configuration from "./Configuration";
import Reportes from "./Reportes";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import { getUserByToken } from "../../crud/auth.crud";
import * as auth from "../../store/ducks/auth.duck";
import { useSelector, useDispatch } from 'react-redux'

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default () => {
  const authConfig = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if(authConfig.loading) {
      dispatch(auth.actions.requestUser());
    }
  }, []);

  if(authConfig.loading) {return (<Suspense fallback={<LayoutSplashScreen />}> </Suspense>)}

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/reportes" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/db" component={DB} />
        <Route path="/configuration" component={Configuration} />
        <Route path="/reportes" component={Reportes} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
