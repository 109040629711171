import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Select from "react-select";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import { connect, useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { metronic, toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { components } from "../../utils";

import * as sigatConfig from "../../../sigat/constants";
import * as config from "../../store/ducks/configuration.duck";

const useStyles2 = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  }
}));

export default () => {

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const classes2 = useStyles2();
  // const { lang, iconType, setLanguage } = this.props;
  const dispatch = useDispatch();
  const authSetup = useSelector(state => state.auth);

  const configSetup = useSelector(state => state.configuration);

  const selectStyle = {
    control: (_, { selectProps: { width } }) => ({
      width
    })
  };

  useEffect(() => {
    if (!authSetup.loading && authSetup.user && authSetup.user.applications) {
      const app = authSetup.user.applications[sigatConfig.MODULE_ID];
      dispatch(
        config.actions.setClients([...app.clients])
      );
      dispatch(
        config.actions.setTableauSite(app.tableau_site)
      );
    }
  }, [authSetup.loading]);

  const { client, clients } = configSetup;

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs"
      drop="down"
      alignRight
      style={{
        minWidth: "100px",
        padding: "20px"
      }}
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
        <span
          className={clsx("kt-header__topbar-icon", {
            "kt-header__topbar-icon--brand": true
          })}
          style={{
            minWidth: "200px",
            padding: "10px"
          }}
        >
          <span
            className="kt-nav__link-text"
            style={{
              color: "white"
            }}
          >
            {client ? client.name : "No hay cliente seleccionado"}
          </span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">

        <ul className="kt-nav kt-margin-t-10 kt-margin-b-10" style={{
          maxHeight: '400px',
          overflowY: 'scroll',
        }}>
            <form className="kt-quick-search__form">
              <div
                className={clsx("input-group", {
                  "kt-spinner kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right": loading
                })}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="flaticon2-search-1" />
                  </span>
                </div>

                <input
                  type="text"
                  autoFocus={true}
                  placeholder="Buscar..."
                  value={filter}
                  onChange={(evt) => {
                    setFilter(evt.target.value);
                  }}
                  className="form-control kt-quick-search__input"
                />

                <div className="input-group-append">
                  <span className="input-group-text">
                    <i
                      style={{ display: "flex" }}
                      onClick={() => {
                        setFilter('');
                      }}
                      hidden={!clients || (clients && !clients.length)}
                      className="la la-close kt-quick-search__close"
                    />
                  </span>
                </div>
              </div>
            </form>
          {clients.filter((item) => {
            if(filter) {
              return item.name.toLowerCase().includes(filter.toLowerCase()) ;
            } else return true;
          }).map(item => (
            <li
              key={item.name}
              className={clsx("kt-nav__item", {
                "kt-nav__item--active":
                  client && item.name === client.name
              })}
            >
              <span
                onClick={() => {
                  dispatch(config.actions.setClient(item));
                }}
                className={clsx("kt-nav__link", {
                  "kt-nav__link--active":
                    client && item.name === client.name
                })}
              >
                <span className="kt-nav__link-text">{item.name}</span>
              </span>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};
