import React, { Component, useMemo, useState, useEffect, useRef } from "react";
import { useDebounce } from "use-debounce";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import { DropzoneDialog } from "material-ui-dropzone";
import EnhancedTable from "./report/EnhancedTable";
import Editable from "./report/Editable";
import useEscape from '../../useEscape';
import { debounce } from "lodash";

import { emphasize, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";

import { components } from "../../utils";
import ReactSelect from "react-select";
import {
  FormGroup,
  NoSsr,
  Grid,
  IconButton,
  InputBase,
  Paper 
} from "@material-ui/core";
import SigatBar from "../../partials/sigat/dashboard/SigatBar";

import { ErrorPage2 } from "../errors/ErrorPage2";

import { getDB, uploadDB, downloadDB } from "../../crud/db.crud";
import { update as updateMuestraRv } from "../../crud/muestra_rv.crud";
import { update as updateMuestraMc } from "../../crud/muestra_mc.crud";
import { list as listCalificaciones } from "../../crud/calificacion_metodo.crud";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const useStyles2 = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  }
}));

const DB = props => {
  const [files, setFiles] = useState([]);
  const [calificaciones, setCalificaciones] = useState([]);
  const [openedZone, setOpenedDropZone] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [filtering] = useState(false);
  const [muestras, setMuestras] = useState({
    header: [],
    result: []
  });
  const configSetup = useSelector(state => state.configuration);
  const filters = useSelector(state => state.filter);
  const dispatch = useDispatch()
  const classes = useStyles();
  const classes2 = useStyles2();
  const selectStyle = {
    control: (_, { selectProps: { width } }) => ({
      width
    })
  };

  const fetchCalificaciones = async () => {
    const response = await listCalificaciones(configSetup.client.technical_name);
    setCalificaciones(response.data);
  };

  const fetchDB = async () => {
    setLoaderState(true);
    try {
      const response = await getDB(
        configSetup.client.technical_name,
        filters.finca,
        filters.year,
        filters.agrupacion_nombre,
        filters.initialDate,
        filters.finalDate
      );
      setMuestras(response.data);
    } catch (err) {
      setMuestras({
        header: [],
        result: []
      });
    } finally {
      setLoaderState(false);
    }
  };

  const uploadFile = async () => {
    const data = new FormData();
    data.append("file", files[0]);
    data.append("technical_name", configSetup.client.technical_name);
    try {
      const response = await uploadDB(data);
      fetchDB();
    } catch (err) {
      console.error(err);
      alert("Error subiendo el archivo!");
    }
  };

  const onSave = () => {
    if (files.length > 0) uploadFile();
  };

  const fetchDataWithDebounce = debounce(fetchDB, 1000);
  const onFilteredChange = tableState => {
    if (filtering) {
      return fetchDataWithDebounce(tableState);
    } else {
      return fetchDB(tableState);
    }
  };


  useEffect(() => {
    const { client } = configSetup;
    if (client && filters.finca && filters.initialDate && filters.finalDate && !loaderState) {
      fetchDB();
    }
  }, [configSetup, filters]);

  useEffect(() => {
      fetchCalificaciones();
  }, [configSetup])
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter }
  }) => {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Filtrar...`}
      />
    );
  };

  const calificacionesJSX = calificaciones.map(item => {
    return {
      value: item.id,
      label: item.descripcion
    };
  });
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData
  }) => {
    let item = null
    try {
      item = muestras && muestras.result.length > 0 && muestras.result[index][id];
    } catch (err) {console.error(err)}   
    const isRv = item && item.id && item.calificacion_descripcion === undefined;

    const [value, setValue] = React.useState(initialValue);
    const [isEditing, setEditing] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [valueDebounced] = useDebounce(value, 400);

    const inputRef = useRef();

    const updateRv = async () => {
      try {
        setSubmitting(true)
        const response = await updateMuestraRv(
          configSetup.client.technical_name,
          item.id,
          {
            valor: value
          }
        );
        const muestrasTmp = {
          header: [...muestras.header],
          result: [...muestras.result]
        };

        muestrasTmp["result"][index][id].valor = value;
        setMuestras(muestrasTmp);
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    };

    const updateMc = async calificacion_id => {
      try {
        const response = await updateMuestraMc(
          configSetup.client.technical_name,
          item.id,
          { metodo_calificacion: calificacion_id }
        );
        const muestrasTmp = {
          header: [...muestras.header],
          result: [...muestras.result]
        };

        setMuestras(muestrasTmp);
      } catch (e) {
        console.error(e);
      } finally {
      }
    };

    const onChange = e => {
      setValue(e.target.value);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    let inputChild = (
      <Paper component="form" className={classes.root}>
        <InputBase
          rowsMax={1}
          type={'text'}
          onChange={onChange}
          inputRef={inputRef}
          value={value}
          className={classes.input}
          placeholder="Actualizar valor"
          inputProps={{ "aria-label": "update value" }}
        />
        <IconButton
          disabled={submitting}
          type="button"
          className={classes.iconButton}
          aria-label="search"
          onClick={e => {
            e.preventDefault();
            if (isRv && valueDebounced !== initialValue) {
              updateRv();
            } else if (!isRv && valueDebounced !== initialValue) {
            } else {
              setEditing(false);
            }
          }}
        >
          <SaveIcon />
        </IconButton>
      </Paper>
    );

    if(submitting) {
      return <div>
          <span>{'Guardando...'}</span>
        </div>
    }
    if(!item) {
      return <div>
          <span>{initialValue || "-"}</span>
        </div>

    }
    if (!isRv && item && item.id) {
      inputChild=(
      <FormGroup>
        <NoSsr key="nossr22">
          <ReactSelect
            autoFocus
            ref={inputRef}
            value={{ label: value, value: item.calificacion_id }}
            classes={classes2}
            styles={selectStyle}
            inputId="react-select-single"
            onChange={(calificacionItem) => {
            if (!isRv && calificacionItem.label !== initialValue) {
              updateMc(calificacionItem.value);
              setValue(calificacionItem.label);

              const muestrasTmp = {
                header: [...muestras.header],
                result: [...muestras.result]
              };

              muestrasTmp["result"][index][id].calificacion_descripcion = calificacionItem.label;
              setMuestras(muestrasTmp);
            }
            }}
            TextFieldProps={{
              label: "Calificación",
              InputLabelProps: {
                htmlFor: "react-select-single",
                shrink: true
              },
              placeholder: "Escoja una calificación"
            }}
            options={calificacionesJSX}
            components={components}
          />
        </NoSsr>
      </FormGroup>

      );
    }
    return (
      <Editable
        isEditing={isEditing}
        setEditing={setEditing}
        submitFn={() => {
          if (isRv && value !== initialValue) {
            setEditing(false);
            updateRv();
          }
        }}
        initialValue={initialValue}
        childRef={inputRef}
        text={value}
        placeholder="Ingrese un valor"
        type="input"
      >
        {inputChild}
      </Editable>
    );
  };

  const columnsParsed = muestras.header.map(item => {
    const dictionary = {
      Cell: EditableCell,
      Filter: DefaultColumnFilter,
      width: 10,
      id:
        item.sem ||
        item.finca ||
        item.agrupacion_id ||
        item.accessor ||
        (item.muestra_variable_medicion_id && `${item.variable_medicion_edad_planta_id}-${item.muestra_variable_medicion_id}`),
      Header:
        (item.sem && "SM") ||
        (item.finca && "FINCA") ||
        (item.agrupacion && "AGRUPACION") ||
        (item.lote && "LOTE") ||
        item.abreviatura ||
        `${item.variable_medicion_edad_planta} - ${item.variable_medicion_abreviatura}`,
      header:
        (item.sem && "SEM") ||
        (item.finca && "FINCA") ||
        (item.agrupacion && "AGRUPACION") ||
        (item.lote && "LOTE") ||
        item.abreviatura ||
        `${item.variable_medicion_edad_planta} - ${item.variable_medicion_abreviatura}`,
      accessor: (originalRow, rowIndex) => {
        if (item.sem) return originalRow.sem;
        if (item.finca) return originalRow.finca;
        if (item.agrupacion) return originalRow.agrupacion_nombre;
        if (item.lote) return originalRow.lote;

        return (
          (originalRow[item.accessor] &&
           (originalRow[item.accessor].calificacion_descripcion ||
            originalRow[item.accessor].valor)) || '-'
        )
      }
    };

    return dictionary;
  });

  const columns = useMemo(() => columnsParsed, [columnsParsed]);

  const [data, setData] = useState(useMemo(() => muestras.result, [muestras]));
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    setData(muestras.result);
  }, [muestras.result]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  if (!configSetup.client) {
    return <ErrorPage2 />;
  }

  return (
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <SigatBar />
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12"></div>
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      BASE DE DATOS EVALUACION DE SIGATOKA{" "}
                    </h3>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      {/* <CssBaseline /> */}
                      <EnhancedTable
                        uploadData={() => {
                          setOpenedDropZone(true);
                        }}
                        downloadData={async () => {
                          const response = await downloadDB(
                            configSetup.client.technical_name,
                            {
                            finca: filters.finca,
                            year: filters.year,
                            agrupacion: filters.agrupacion_id,
                            fecha_inicio: filters.initialDate,
                            fecha_fin: filters.finalDate
                          });
                          const url = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', 'reporte.xlsx');
                          document.body.appendChild(link);
                          link.click();
                        }}
                        filtering={filtering}
                        columns={columns}
                        data={data}
                        setData={setData}
                        updateMyData={updateMyData}
                        skipPageReset={skipPageReset}
                        fetchData={fetchDB}
                        onFilteredChange={onFilteredChange}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="kt-portlet__foot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DropzoneDialog
        dialogTitle={"Importar"}
        dropzoneText={"Suba su DBe"}
        filesLimit={1}
        open={openedZone}
        showPreviews={true}
        onSave={onSave}
        onClose={() => {
          setOpenedDropZone(false);
        }}
        onChange={files => {
          setFiles(files);
        }}
      />
    </React.Fragment>
  );
};

export default DB;
