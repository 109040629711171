import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from '../../IQDatatable';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import Actions from '../shared/CreateActions';
import { TextField } from "@material-ui/core";
import { get, list, update, remove, create } from "../../../../crud/agrupacion.crud";
import { list as listSuperficies} from "../../../../crud/superficie.crud";
import { remove as removeAgrupacionSuperficie, create as createAgrupacionSuperficie } from "../../../../crud/agrupacion_superficie.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import AgrupacionSuperficieDialog from './AgrupacionSuperficieDialog'

import ConfigHeader from '../ConfigHeader'
import reducer,  * as ducks from './reducer';

const Edit = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [initialForm, setInitialForm] = useState({nombre: '', activo: null, superficies: []})

  const [superficies, setSuperficies] = useState([]);
  const [supSelected, setSupSelected] = useState(null);

  const superficiesJSX = superficies.map((item) => {
    return <option value={item.id}>{ item.nombre }</option>
  })

  const cbReset = () => {
    fetchData();
  }

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await get(configSetup.client.technical_name, props.match.params.id);
      setInitialForm(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };
  const fetchSuperficies = async () => {
    try {
      const response = await listSuperficies(configSetup.client.technical_name);
      setSuperficies(response.data)
      setSupSelected((response.data && response.data.length> 0 && response.data[0].id) || null)
    } catch (err) {
      console.error(err)
    } finally {}
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
      fetchSuperficies()
    }
  }, [configSetup.client]);

  const columns = [
    {
      name: 'Nombre',
      selector: 'superficie.nombre',
      sortable: true,
    },
    {
      name: 'Año',
      selector: 'anio',
      sortable: true,
    },
    {
      name: 'Semana de inicio',
      selector: 'semana_inicio',
      sortable: true,
    },
    {
      name: 'Semana de Fin',
      selector: 'semana_fin',
      sortable: true,
    },
    // {
    //   cell: row => <button
    //   onClick={((evt) => {
    //     evt.preventDefault();
    //     const agrupSuperficie = initialForm.agrupacion_superficies.find((item) => {
    //       return item.superficie === row.id;
    //     });
    //     if(agrupSuperficie) {
    //       removeAgrupacionSuperficie(configSetup.client.technical_name, agrupSuperficie.id).then((response) => {
    //         fetchData();
    //       }).catch((err) => {
    //         console.error(err);
    //       })
    //     }
    //   })}
    //                 id="kt_login_signin_submit"
    //                 type="submit"
    //                 className={`btn btn-primary`}
    //               >
    //                 Remover
    //               </button>
    //   ,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/agrupacion'}
                name={'Agrupaciones'}
                stage={'Edición'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Edici&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              nombre: initialForm.nombre,
              activo: initialForm.activo 
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.nombre = 'Ingrese un nombre';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                update(configSetup.client.technical_name, initialForm.id, {nombre: values.nombre, activo: values.activo})
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/agrupacion/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando edad de planta');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}
                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>
                <div className="form-group">
                  <AgrupacionSuperficieDialog
                    padre={{ ...initialForm }}
                    cb={cbReset}
                  />
                </div>
                <div className="form-group">
                <DataTable
              title="Superficies"
              columns={columns}
                  data={initialForm.agrupacion_superficies}
                />
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/agrupacion/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(Edit);
