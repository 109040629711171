import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import Actions from '../shared/CreateActions';
import ConfigHeader from '../ConfigHeader'
import { TextField } from "@material-ui/core";
import { get, list, update, remove, create } from "../../../../crud/variableMedicion.crud";
import { remove as removeRangoVariableMedicion, create as createRangoVariableMedicion } from "../../../../crud/rango_variable_medicion.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import reducer,  * as ducks from './reducer';


const BANNED_STRS = ['HOJA 3', 'HOJA 4', 'HOJA 5'];

const containsBanned = (abreviatura) => {
  return BANNED_STRS.reduce((result, item) => {
    if(abreviatura.includes(item)) result = true;
    return result;
  }, false);
};


const Edit = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [initialForm, setInitialForm] = useState({nombre: '', abreviatura: '', activo: null})
  const [ranges, setRanges] = useState({min: 0, max: 0})

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
   const configSetup = useSelector(state => state.configuration);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await get(configSetup.client.technical_name, props.match.params.id);
      setInitialForm(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  const rangoVariableColumns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Minimo',
      selector: 'min',
      sortable: true,
    },
    {
      name: 'Maximo',
      selector: 'max',
      sortable: true,
    },
    {
      cell: row => <button
      onClick={((evt) => {
        evt.preventDefault();
        const rangoVarMedicion = initialForm.r_mediciones.find((item) => {
          return item.variable_medicion === row.variable_medicion;
        });
        if(rangoVarMedicion) {
          removeRangoVariableMedicion(configSetup.client.technical_name, rangoVarMedicion.id).then((response) => {
            fetchData();
          }).catch((err) => {
            console.error(err);
          })
        }
      })}
                    id="kt_login_signin_submit"
                    type="submit"
                    className={`btn btn-primary`}
                  >
                    Remover
                  </button>
      ,
      allowOverflow: true,
      button: true,
    },
    ]

  const isHoja = containsBanned(initialForm.abreviatura);

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/variable_medicion'}
                name={'Variables de Medición'}
                stage={'Editar'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Edici&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              nombre: initialForm.nombre,
              activo: initialForm.activo,
              abreviatura: initialForm.abreviatura,
              edad_planta_nombre: initialForm.edad_planta_nombre
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 
              if (!values.abreviatura) {
                errors.abreviatura = 'Ingrese una abreviatura';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                update(configSetup.client.technical_name, initialForm.id,
                       {
                         nombre: values.nombre,
                         abreviatura: values.abreviatura,
                         activo: values.activo})
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/variable_medicion/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando edad de planta');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                {!isHoja && <div className="form-group">
                  <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                      Nuevo Rango
              </Grid>
              <Grid item xs={6}>
                  <TextField
                    type="number"
                    label="Minimo"
                    margin="normal"
                    className="kt-width-full"
                    name="min"
                    value={ranges.min}
                    onChange={(evt) => {
                      setRanges({
                        ...ranges,
                        min: evt.target.value
                      })
                    }}
                  />
              </Grid>
              <Grid item xs={6}>
                  <TextField
                    type="number"
                    label="Maximo"
                    margin="normal"
                    className="kt-width-full"
                    name="max"
                    value={ranges.max}
                    onChange={(evt) => {
                      setRanges({
                        ...ranges,
                        max: evt.target.value
                      })
                    }}
                  />
              </Grid>
            </Grid>
                  <button
                    onClick={((evt) => {
                      evt.preventDefault();
                      createRangoVariableMedicion({ technical_name: configSetup.client.technical_name}, {
                        variable_medicion: initialForm.id, min: ranges.min, max: ranges.max}).then(() => {
                          fetchData()
                        })
                    })}
                    id="kt_login_signin_submit"
                    className={`btn btn-primary`}
                  >
                    Agregar Rango
                  </button>
                </div>
}
                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre || ''}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    label="abreviatura"
                    margin="normal"
                    className="kt-width-full"
                    name="abreviatura"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.abreviatura || ''}
                    helperText={touched.abreviatura && errors.abreviatura}
                    error={Boolean(touched.abreviatura && errors.abreviatura)}
                    floatingLabelFixed={true}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    disabled
                    type="text"
                    label="Edad Planta"
                    margin="normal"
                    className="kt-width-full"
                    name="Edad Planta"
                    value={values.edad_planta_nombre || ''}
                    floatingLabelFixed={true}
                  />
                </div>

                {!isHoja && <div className="form-group">
                  <DataTable
                    title="Rango de Variables de Medicion"
                    columns={rangoVariableColumns}
                    data={initialForm.r_mediciones}
                  />
                </div> }

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/variable_medicion/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(Edit);
