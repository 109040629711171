export const SET_DATA = 'SET_DATA';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE = 'UPDATE_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_CURR_ITEM = 'SET_CURR_ITEM';
export const SET_RANGES = 'SET_RANGES';
export const ADD_RANGE = 'ADD_RANGE';
export const UPDATE_RANGE = 'UPDATE_RANGE';
export const REMOVE_RANGE = 'REMOVE_RANGE';

export const initialState = {
  data: [],
  current: undefined,
  ranges: []
}

export default function reducer(state, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload
      };
    case SET_RANGES:
      return {
        ...state,
        ranges: action.payload
      };
    case ADD_RANGE:
      return {
        ...state,
        ranges: [...state.ranges, action.payload]
      };

  case UPDATE_RANGE: {
      const updatedItem = { ...action.payload };
      const updatedItemIndex = state.ranges.findIndex(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.ranges.slice(0, updatedItemIndex),
        updatedItem,
        ...state.ranges.slice(updatedItemIndex + 1)
      ];
      return {
        ...state,
        current: {},
        ranges: updatedData
      };

    }
  case REMOVE_RANGE: {
      const index = state.ranges.findIndex(
        item => item.color === action.payload.color
      );
    if(index >=0) {
      const updatedData = [
        ...state.ranges.slice(0, index),
        ...state.ranges.slice(index + 1)
      ];
      return {
        ...state,
        ranges: updatedData
      };
    }
    break;
  }

  case ADD_ITEM:
    return {
      ...state,
      data: [...state.data, action.payload]
      };
case SET_CURR_ITEM:
      return {
        ...state,
        current: action.payload
      };
    case UPDATE:
      const updatedItem = { ...action.payload };
      const updatedItemIndex = state.data.findIndex(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.data.slice(0, updatedItemIndex),
        updatedItem,
        ...state.data.slice(updatedItemIndex + 1)
      ];
      return {
        ...state,
        current: {},
        data: updatedData
      };
    case REMOVE_ITEM:
      const index = state.data.findIndex(
        item => item.id === action.payload.id
      );
    if(index >=0) {
      const item = state.data.find(
        item => item.id === action.payload.id
      );
      const updatedData = [
        ...state.data.slice(0, index),
        { ...item, activo: false },
        ...state.data.slice(index + 1)
      ];

      return {
        ...state,
        current: {},
        data: updatedData
      };
    }
      return {
        ...state,
      };
    default:
      return state;
  }
}
