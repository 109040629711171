import axios from "axios";
import * as crudConfig from "./config.crud";

const { axiosClient, baseURL } = crudConfig;

export const LOGIN_URL = `${baseURL}/v1/users/token/`;
export const REFRESH_URL = `${baseURL}/v1/users/token/refresh/`;
export const REGISTER_URL = `${baseURL}/v1/auth/register/`;
export const REQUEST_PASSWORD_URL = `${baseURL}/v1/auth/forgot-password/`;

export const ME_URL = `${baseURL}/v1/users/userinfo/`;

export function login(email, password) {
  return axiosClient.post(LOGIN_URL, { username: email, password });
}

export function register(email, fullname, username, password) {
  return axiosClient.post(REGISTER_URL, {
    email,
    fullname,
    username,
    password
  });
}

export function requestPassword(email) {
  return axiosClient.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return axiosClient.get(ME_URL);
}
