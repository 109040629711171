import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

export default function ConfigHeader(props) {
  const classes = useStyles();
  const {
    parentHref,
    href,
    name,
    stage,
    type
  } = props;

const ConfigurationLink = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} to={parentHref} {...props} />
));

const ChildLink = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} to={href} {...props} />
));



  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
      <Link color="inherit" component={ConfigurationLink} >
      Reporter&iacute;a
          </Link>
      <Link color="inherit" component={ChildLink}>
      {name}
          </Link>
          <Typography color="textPrimary">{stage}</Typography>
        </Breadcrumbs>
      </Paper>
    </div>
  );
}
