
import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

import { PropTypes } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NivelFilter = ({
  nivel, onChange, niveles
}) => {

  const classes = useStyles();
  const nivelesJSX = Object.keys(niveles).map((key) => {
    return (<MenuItem value={key}>{niveles[key]}</MenuItem>)
  })

  return (
    <>
      <Divider />
    <MenuItem>
      <FormControl className={classes.formControl}>
    <InputLabel id="edad_planta_label">Nivel</InputLabel>
      <Select
        disabled
        name="nivel"
        labelId="nivel_label"
        id="nivel"
        value={nivel && nivel.toString()}
        onChange={onChange}
      >
        <MenuItem value={'-1'}>TODOS</MenuItem>
        {nivelesJSX}
      </Select>
    </FormControl>
    </MenuItem>
    </>
  );
}

export default React.memo(NivelFilter);
