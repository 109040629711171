import * as crudConfig from "./config.crud";

const { axiosClient, baseURL } = crudConfig;

export const REPORT_INDICADORES_URL = `${baseURL}/v1/sigatoka/dia/indicadores/`;
export const REPORT_RESUMEN_RV_URL = `${baseURL}/v1/sigatoka/dia/resumen_rv/`;
export const REPORT_RESUMEN_MC_URL = `${baseURL}/v1/sigatoka/dia/resumen_mc/`;
export const REPORT_MAPA_URL = `${baseURL}/v1/sigatoka/dia/mapa/`;
export const REPORT_MAPA_LABORES_URL = `${baseURL}/v1/sigatoka/dia/mapa_labores/`;
export const REPORT_MAPA_EVALUACIONES_URL = `${baseURL}/v1/sigatoka/dia/mapa_evaluaciones/`;
export const REPORT_FOTOS_URL = `${baseURL}/v1/sigatoka/dia/fotos/`;


export const getIndicadores = (technical_name, params) =>
  axiosClient.get(REPORT_INDICADORES_URL, {
    params: { db_tag: technical_name, ...params }
  });

export const getResumenRV = (technical_name, params) =>
  axiosClient.get(REPORT_RESUMEN_RV_URL, {
    params: { db_tag: technical_name, ...params }
  });

export const getResumenMC = (technical_name, params) =>
  axiosClient.get(REPORT_RESUMEN_MC_URL, {
    params: { db_tag: technical_name, ...params }
  });

export const getMapa = (technical_name, params) =>
  axiosClient.get(REPORT_MAPA_URL, {
    params: { db_tag: technical_name, ...params }
  });

export const getMapaLabores = (technical_name, params) =>
  axiosClient.get(REPORT_MAPA_LABORES_URL, {
    params: { db_tag: technical_name, ...params }
  });

export const getMapaEvaluaciones = (technical_name, params) =>
  axiosClient.get(REPORT_MAPA_EVALUACIONES_URL, {
    params: { db_tag: technical_name, ...params }
  });

export const getFotos = (technical_name, params) =>
  axiosClient.get(REPORT_FOTOS_URL, {
    params: { db_tag: technical_name, ...params }
  });
