import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import DatatableMenu from '../shared/DatatableMenu';

import Actions from '../shared/CreateActions';
import { TextField } from "@material-ui/core";
import { list, update, remove, create } from "../../../../crud/umbral.crud";
import { list as listEdadPlantas} from "../../../../crud/edadPlanta.crud";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button
} from "@material-ui/core";

import ConfigHeader from '../ConfigHeader'
import CustomSnack from '../../../../partials/ui/snackContent';
import * as configurationConstants from '../../../../../helpers/ConfigurationConstants';

import reducer,  * as umbralDucks from './reducer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const New = props => {
  const {
    edadPlantaId,
    isDialog,
    onUmbralCreated
  } = props;

  const initialRangeState = {min: 0, max: 0, color: 1}
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [ranges, setRanges] = useState(initialRangeState)
  const [edadPlantas, setEdadPlantas] = useState([]);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const { COLORES, TIPOS_UMBRAL } = configurationConstants;
  const coloresJSX = COLORES.map((item) => {
    return (<MenuItem value={item.num}>{item.value}</MenuItem>)
  })
  const tiposJSX = Object.keys(TIPOS_UMBRAL).map((key) => {
    const constants = TIPOS_UMBRAL
    return (<MenuItem value={key}>{constants[key]}</MenuItem>)
  })

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  }

  const edadPlantasJSX = edadPlantas.map((item) => {
    return <MenuItem value={item.id}>{ item.nombre }</MenuItem>
  })

  const fetchEdadPlantas = async () => {
    try {
      const response = await listEdadPlantas(configSetup.client.technical_name);
      setEdadPlantas(response.data)
    } catch (err) {
      console.error(err)
    } finally {}
  };


  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, umbralDucks.initialState)
   const configSetup = useSelector(state => state.configuration);

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
      fetchEdadPlantas()
    }
  }, [configSetup.client]);

  useEffect(() => {
    setRanges(initialRangeState)
  }, [state.ranges.length]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name);
      dispatch({type: umbralDucks.SET_DATA, payload: response.data});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  const removeItem = async (color) => {
    try {
      dispatch({type: umbralDucks.REMOVE_RANGE, payload: {color}});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      name: 'Color',
      selector: 'color',
      sortable: true,
      cell: row => 
        <div>
          { COLORES.find((item) => {
            return item.num === row.color
          }).value }
        </div>
    },
    {
      name: 'Minimo',
      selector: 'min',
      sortable: true,
    },
    {
      name: 'Maximo',
      selector: 'max',
      sortable: true,
    },
    {
      cell: row => <DatatableMenu size="small" row={row}
      onDeleteRow={() => {
        removeItem(row.color);
      }}
      onEditRow={() => {
        history.push(`/configuration/calificacion_metodo/edit/${row.id}`)
      }}
        />,
      allowOverflow: true,
      button: true,
      width: '56px',
    },

  ]

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        { !isDialog && (
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
              <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/umbral'}
                name={'Umbrales'}
                stage={'Nuevo'}
              />
              </div>
          </div>
        </div>) }
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
            { !isDialog && (
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Nuevo Umbral
                  </div>
                </div>
            )}
                  <div className="kt-portlet__body">
          <Formik
            initialValues={{
              nombre: "",
              edad_planta: edadPlantaId,
              activo: true,
              tipo_umbral : undefined,
            }}
            validate={values => {
              const errors = {};

              if (!values.nombre) {
                errors.nombre = 'Ingrese un nombre';
              } 

              if (!values.edad_planta) {
                errors.edad_planta = 'Seleccione una edad de planta';
              } 

              if (!values.tipo_umbral) {
                errors.edad_planta = 'Seleccione un tipo de umbral';
              } 
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                create(
                  configSetup.client.technical_name,
                  {
                    tipo_umbral: values.tipo_umbral,
                    nombre: values.nombre,
                    edad_planta: values.edad_planta,
                    rango_umbrales: state.ranges,
                    activo: values.activo})
                  .then(() => {
                    disableLoading();
                    if(!isDialog) {
                      history.push('/configuration/umbral/list')
                    } else {
                      onUmbralCreated()
                    }
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando edad de planta');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </div>

                <div className="form-group">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="edad_planta_label">Edad Planta</InputLabel>
                        <Select
                          disabled={edadPlantaId}
                          name="edad_planta"
                          labelId="edad_planta_label"
                          id="edad_planta"
                          value={values.edad_planta}
                          onChange={handleChange}
                        >
                          {edadPlantasJSX}
                        </Select>
                      </FormControl>
                      {errors.edad_planta &&
                       touched.edad_planta &&
                       <div className="input-feedback">
                         {errors.edad_planta}
                       </div>}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="tipo_label">Tipo de Umbral</InputLabel>
                        <Select
                          name="tipo_umbral"
                          labelId="tipo_label"
                          id="tipo_umbral"
                          value={values.tipo_umbral}
                          onChange={handleChange}
                        >
                          {tiposJSX}
                        </Select>
                      </FormControl>
                      {errors.tipo_umbral &&
                       touched.tipo_umbral &&
                       <div className="input-feedback">
                         {errors.tipo_umbral}
                       </div>}
                    </Grid>
                  </Grid>
                </div>
                <div className="form-group">
                  <DataTable
                    title="Rangos"
                    columns={columns}
                    data={state.ranges}
                  />
                </div>
                
                <div className="form-group">
                  <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                      Formulario de nuevo rango: 
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type="number"
                        label="Minimo"
                        margin="normal"
                        className="kt-width-full"
                        name="min"
                        value={ranges.min}
                        onChange={(evt) => {
                          setRanges({
                            ...ranges,
                            min: evt.target.value
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type="number"
                        label="Maximo"
                        margin="normal"
                        className="kt-width-full"
                        name="max"
                        value={ranges.max}
                        onChange={(evt) => {
                          setRanges({
                            ...ranges,
                            max: evt.target.value
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Color</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ranges.color}
                          onChange={(evt) => {
                            setRanges({
                              ...ranges,
                              color: evt.target.value
                            })
                          }}
                        >
                          {coloresJSX}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <button
                    onClick={((evt) => {
                      evt.preventDefault();
                      const colorFound = state.ranges.find((item) => {
                        return item.color === ranges.color
                      });
                      if(colorFound) {
                        setOpenSnack(true);
                      } else {
                        dispatch({type: umbralDucks.ADD_RANGE, payload:
                                  {id: null, color: ranges.color, min: ranges.min, max: ranges.max}});
                      }
                    })}
                    id="kt_login_signin_submit"
                    className={`btn btn-primary`}
                  >
                    Agregar Rango
                  </button>
                </div>
                
                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/umbral/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomSnack
        open={openSnack}
        setOpen={setOpenSnack}
        variant={'error'}
        message={'Error agregando!'}
        handleClose={handleClose}
      />
    </React.Fragment>
  )
}

export default React.memo(New);
