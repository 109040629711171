import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { PropTypes } from 'prop-types';
import {
  LoadScript,
  GoogleMap,
} from "@react-google-maps/api";


const containerStyle = {
  width: '100%',
  height: '400px'
};

const API_KEY = "AIzaSyAaLJvNuFG6elskiX3RV5rA5_kKzMX9ltw";
const MapWithAMarker = (({ data, dataMapLabores, dataMapOrigen, dataMapEnviada, markers }) => {
      const [map, setMap] = React.useState(null)
      useEffect(() => {
        if(map) {
          let bounds = new window.google.maps.LatLngBounds();
        if(data && data.length > 0) {
          data && data.forEach((marker, index) => {
            const { data: detalleMuestra, muestra } = marker;
            let coord = { lng: null, lat: null }
    
            if(detalleMuestra.length > 0) {
              coord.lat = detalleMuestra[0].latitud
              coord.lng = detalleMuestra[0].longitud
            }
            const position = new window.google.maps.LatLng(coord.lat, coord.lng);
            bounds.extend(position);
          });

          map.fitBounds(bounds)
        }

          dataMapLabores && dataMapLabores.forEach((marker, index) => {
            const { data: detalleMuestra, muestra } = marker;
            let coord = { lng: null, lat: null }
    
            if(detalleMuestra.length > 0) {
              coord.lat = detalleMuestra[0].latitud
              coord.lng = detalleMuestra[0].longitud
            }
            const position = new window.google.maps.LatLng(coord.lat, coord.lng);
            bounds.extend(position);
          });

          dataMapOrigen && dataMapOrigen.forEach((marker, index) => {
            const { data: detalleMuestra, muestra } = marker;
            let coord = { lng: null, lat: null }
    
            if(detalleMuestra.length > 0) {
              coord.lat = detalleMuestra[0].latitud
              coord.lng = detalleMuestra[0].longitud
            }
            const position = new window.google.maps.LatLng(coord.lat, coord.lng);
            bounds.extend(position);
          });

          dataMapEnviada && dataMapEnviada.forEach((marker, index) => {
            const { data: detalleMuestra, muestra } = marker;
            let coord = { lng: null, lat: null }
    
            if(detalleMuestra.length > 0) {
              coord.lat = detalleMuestra[0].latitud
              coord.lng = detalleMuestra[0].longitud
            }
            const position = new window.google.maps.LatLng(coord.lat, coord.lng);
            bounds.extend(position);
          });

        }
      }, [map, data, dataMapLabores, dataMapOrigen, dataMapEnviada]);
      const onLoad = React.useCallback(function callback(map) {
        setMap(map)
      }, [])

      return (
    <LoadScript
      googleMapsApiKey={API_KEY}
    >
  <GoogleMap
    mapContainerStyle={containerStyle}
    disableDefaultUI={ true }
    mapTypeId={'satellite'}
    onLoad={onLoad}
    defaultCenter={{lat:0, lng:0}}
    defaultZoom={6} >
    {markers}
  </GoogleMap>
    </LoadScript>
  )}
);

const Map = ({ data, markers }) => {
  return (
    <MapWithAMarker
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      markers={markers}
      data={data}
    />);
}

Map.propTypes = {
  name: PropTypes.string,
  markers: PropTypes.arrayOf(PropTypes.shape({
  })),
};

export default React.memo(Map);
