import React from "react";

import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { useHistory } from "react-router-dom";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import {
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

let keys = [
  {
    name: 'Configuración General',
    description: 'Aquí puede configurar fechas generales, ...',
    url: '/configuration/general',
    bg: `/media/bg/calendar.jpg`,
  },
  {
    name: 'Superficies',
    description: 'Plantas disponibles para medición',
    url: '/configuration/superficies',
    bg: `/media/bg/plantacion`,
  },
  {
    name: 'Edad Plantas',
    description: 'Plantas disponibles para medición',
    url: '/configuration/edad_planta',
    bg: `/media/bg/imagen4`,
  },
  {
    name: 'Agrupaciones',
    description: 'Conjuntos de superficies',
    url: '/configuration/agrupacion',
    bg: `/media/bg/imagen1`,
  },
  {
    name: 'Variables de Medicion',
    description: 'Variables a usarse',
    url: '/configuration/variable_medicion',
    bg: `/media/bg/imagen7`,
  },
  {
    name: 'Métodos de Calificación',
    description: 'Definición de castigos',
    url: '/configuration/calificacion_metodo',
    bg: `/media/bg/imagen3`,
  },
  {
    name: 'Umbrales',
    description: 'Manejo de Rangos',
    url: '/configuration/umbral',
    bg: `/media/bg/imagen2`,
  },
  {
    name: 'Supervisores',
    description: 'Manejo de Supervisores',
    url: '/configuration/supervisor',
    bg: `/media/bg/supervisor.jpg`,
  },
  {
    name: 'Emision Foliar',
    description: 'Manejo de Valores de emisión foliar',
    url: '/configuration/emision-foliar',
  },
]

if(process.env.NODE_ENV === 'production') {
  keys = [
  {
    name: 'Agrupaciones',
    description: 'Conjuntos de superficies',
    url: '/configuration/agrupacion',
    bg: `/media/bg/imagen1`,
  },
  {
    name: 'Superficies',
    description: 'Plantas disponibles para medición',
    url: '/configuration/superficies',
    bg: `/media/bg/plantacion`,
  },
  ]
}

const useStyles5 = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%"
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15
      },
      "& $imageMarked": {
        opacity: 0
      },
      "& $imageTitle": {
        border: "4px solid currentColor"
      }
    }
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    utransition: theme.transitions.create("opacity")
  }
}));

export default props => {
  const history = useHistory();
  const classes5 = useStyles5();
  const uiData = keys.map((item) => {
    return (
            <div className="col-md-6">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label" style={{fontWeight: 'bold'}}>
                    {item.name}
                  </div>
                </div>
                  <div className="kt-portlet__body">
        <div className="kt-section">
          <span className="kt-section__sub">
            {item.description}
          </span>
          <div className="kt-separator kt-separator--dashed" />
          <div className="kt-section__content">
            <div className={classes5.root}>
                <ButtonBase
                  onClick={() => {
                    history.push(item.url)
                  }}
                  focusRipple
                  key={item.name}
                  className={classes5.image}
                  focusVisibleClassName={classes5.focusVisible}
                  style={{
                    width: '100%'
                  }}
                >
                  <span
                    style={{
                      backgroundImage: `url('${item.bg}')`,
                      backgroundSize: 'cover',
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                    }}
                  />
                  <span className={classes5.imageBackdrop} />
                  <span className={classes5.imageButton}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes5.imageTitle}
                    >
                      {item.name}
                      <span className={classes5.imageMarked} />
                    </Typography>
                  </span>
                </ButtonBase>
            </div>
          </div>
        </div>
                  </div>
              </div>
            </div>
    )
  });
  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <h3 className="kt-subheader__title">Configuraci&oacute;n</h3>
              <span className="kt-subheader__separator kt-hidden" />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    Descripci&oacute;n
                  </div>
                </div>
                  <div className="kt-portlet__body">
                    Aqu&iacute; se pueden configurar los distintos m&oacute;dulos de la aplicaci&oacute;n de sigatoka
                  </div>
              </div>
            </div>
            {uiData}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
