import React, { useState, useCallback, useEffect, useReducer, useMemo, } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PropTypes } from 'prop-types';
import Bar from './Bar'
import Resumen from './Resumen'
import Gallery from './Gallery'
import MapFilter from './mapFilter'
import ConfigHeader from '../shared/ConfigHeader'
import Indicadores from '../shared/Indicadores'
import Map from '../shared/Map'
import {
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import LoadingOverlay from 'react-loading-overlay';
import {
  getIndicadores,
  getFotos,
  getResumenRV,
  getResumenMC,
  getMapa,
  getMapaLabores,
  getMapaEvaluaciones,
} from "../../../../crud/reporteDiario.crud";

import {
  BAJA,
  MODERADA,
  SEVERA,
  getMarkerColor,
} from '../../../../../sigat/constants';

import reducer, * as reportDucks from './reducer';
import * as reportFilterDuck from "../../../../store/ducks/reportFilter.duck";

const YEAR = 'year'
const WEEK = 'week'
const FINCA = 'finca'
const GROUP = 'group'
const LOTE = 'lote'

const ReporteDiarioHeader = () =>
  <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div className="row">
      <div className="col-md-12">
        <div className="kt-portlet">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                <ConfigHeader
                  parentHref={'/reportes'}
                  href={'/reportes/diario'}
                  name={'Reporte'}
                  stage={'Diario'}
                />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

const Diario = props => {

  const [reportState, dispatchReport] = useReducer(reducer, reportDucks.initialState)
  const configSetup = useSelector(state => state.configuration);
  const reportFilters = useSelector(state => state.reportFilter);
  const [showInfoWindow, toggleInfoWindow] = useState(null);

  const {
    loadIndicadores, loadFotos,
    loadMapa, loadMapaLabores,
    loadResumenRV, loadResumenMC,
    loadMapaEvaluaciones, check
  } = reportState

  const dispatch = useDispatch()

  const setCheck = useCallback((value) => {
    dispatchReport({ type: reportDucks.SET_CHECK, payload: value });
  }, [dispatchReport])

  const cbChangeFilter = (name, value) => {
    if (name === YEAR) {
      dispatch(reportFilterDuck.actions.fetchReportWeeks({ [name]: value }));
    } else if (name === WEEK) {
      dispatch(reportFilterDuck.actions.fetchReportFincas({ [name]: value }));
    } else if (name === FINCA) {
      dispatch(reportFilterDuck.actions.fetchReportGroups({ [name]: value }));
    } else if (name === GROUP) {
      dispatch(reportFilterDuck.actions.fetchReportLotes({ [name]: value === 'all' ? null : value }));
    } else if (name === LOTE) {
      dispatch(reportFilterDuck.actions.setReportFilters({ [name]: value === 'all' ? null : value }));
    }
  };

  const {
    years, weeks, lotes, groups, filters, fincas
  } = reportFilters;

  const fetchIndicadores = async () => {
    let response = {}
    try {
      dispatchReport({ type: reportDucks.LOAD_INDICADORES, payload: {} });
      response = await getIndicadores(configSetup.client.technical_name, filters);
    } catch (err) {
      console.error(err)
    } finally {
      dispatchReport({ type: reportDucks.SET_INDICADORES, payload: response.data });
    }
  };

  const fetchFotos = async () => {
    let response = []
    try {
      dispatchReport({ type: reportDucks.LOAD_FOTOS, payload: {} });
      response = await getFotos(configSetup.client.technical_name, filters);
    } catch (err) {
      console.error(err)
    } finally {
      dispatchReport({ type: reportDucks.SET_FOTOS, payload: response.data });
    }
  };
  const fetchResumenRV = async () => {
    let response = [];
    try {
      dispatchReport({ type: reportDucks.LOAD_RESUMEN_RV, payload: {} });
      response = await getResumenRV(configSetup.client.technical_name, filters);
    } catch (err) {
      console.error(err)
    } finally {
      dispatchReport({ type: reportDucks.SET_RESUMEN_RV, payload: response.data });
    }
  };
  const fetchResumenMC = async () => {
    let response = []
    try {
      dispatchReport({ type: reportDucks.LOAD_RESUMEN_RC, payload: {} });
      response = await getResumenMC(configSetup.client.technical_name, filters);
    } catch (err) {
      console.error(err)
    } finally {
      dispatchReport({ type: reportDucks.SET_RESUMEN_RC, payload: response.data });
    }
  };
  const fetchResumenMapa = async () => {
    let response = []
    try {
      dispatchReport({ type: reportDucks.LOAD_MAPA, payload: {} });
      response = await getMapa(configSetup.client.technical_name, filters);
    } catch (err) {
      console.error(err)
    } finally {
      dispatchReport({ type: reportDucks.SET_MAPA, payload: response.data });
    }
  };

  const fetchResumenMapaLabores = async () => {
    let response = []
    try {
      dispatchReport({ type: reportDucks.LOAD_MAPA_LABORES, payload: {} });
      response = await getMapaLabores(configSetup.client.technical_name, filters);
    } catch (err) {
      console.error(err)
    } finally {
      dispatchReport({ type: reportDucks.SET_MAPA_LABORES, payload: response.data });
    }
  };

  const fetchResumenMapaEvaluaciones = async () => {
    let response = {}
    try {
      dispatchReport({ type: reportDucks.LOAD_MAPA_EVALUACIONES, payload: {} });
      response = await getMapaEvaluaciones(configSetup.client.technical_name, filters);
    } catch (err) {
      console.error(err)
    } finally {
      dispatchReport({ type: reportDucks.SET_MAPA_EVALUACIONES, payload: response.data });
    }
  };
  useEffect(() => {
    dispatch(reportFilterDuck.actions.fetchReportYears());
  }, [configSetup]);

  useEffect(() => {
    if (filters.year && filters.week && filters.finca) {
      fetchIndicadores()
      fetchResumenRV()
      fetchResumenMC()
      fetchResumenMapa()
      fetchResumenMapaLabores()
      fetchResumenMapaEvaluaciones()
      fetchFotos()
    } else {
      dispatchReport({ type: reportDucks.SET_MAPA, payload: [] });
      dispatchReport({ type: reportDucks.SET_MAPA_LABORES, payload: [] });
      dispatchReport({ type: reportDucks.SET_MAPA_EVALUACIONES, payload: { data_geo: [], data_geo_enviada: [] } });
      dispatchReport({ type: reportDucks.SET_INDICADORES, payload: {} });
      dispatchReport({ type: reportDucks.SET_RESUMEN_RV, payload: {} });
      dispatchReport({ type: reportDucks.SET_RESUMEN_RC, payload: {} });
    }
  }, [reportFilters.filters]);

  let markers = (<React.Fragment />)

  const { data: dataMap } = reportState.mapa;
  const { data: dataMapLabores } = reportState.mapaLabores;
  const { data_geo, data_geo_enviada } = reportState.mapaEvaluaciones;

  markers = useMemo(() => {
    if (loadMapa || loadMapaLabores || loadMapaEvaluaciones) return (<React.Fragment />);
    const R = 6378137
    const coordsBackup = [];
    const resultMapDestino = data_geo_enviada && Array.isArray(data_geo_enviada) && data_geo_enviada.map((item, i) => {
      let coord = { lng: null, lat: null }
      while (coordsBackup.includes(item.latitud + item.longitud)) {
        let dn = 20 + Math.floor(Math.random() * Math.floor(30))
        let de = 20 + Math.floor(Math.random() * Math.floor(40))
        let dLat = dn / R
        let dLon = de / (R * Math.cos(Math.PI * item.zlatitud / 180))
        item.latitud = item.latitud + dLat * 180 / Math.PI
        item.longitud = item.longitud + dLon * 180 / Math.PI
      }

      coord.lat = item.latitud
      coord.lng = item.longitud
      coordsBackup.push(item.latitud + item.longitud);

      const detallesJSX = (
        <>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Finca</strong></div>
            <div className="col-md-4">{item.finca}</div>
          </div>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Latitud(Aprox.)</strong></div>
            <div className="col-md-4">{item.latitud}</div>
          </div>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Longitud(Aprox.)</strong></div>
            <div className="col-md-4">{item.longitud}</div>
          </div>
        </>)

      const key = `${item.latitud}${item.longitud}`

      return <Marker
        key={key}
        onClick={() => { toggleInfoWindow(key) }}
        icon={`/media/sigat/marker-icon-yellow.png`}
        position={coord}
      >
        {(showInfoWindow === key) &&
          <InfoWindow
            onCloseClick={() => { toggleInfoWindow(null) }} >
            <div className="container-fluid" style={{ maxWidth: '250px' }}>
              <div className="row">
                <div className="col-md-12"><strong>Evaluacion / Envío</strong></div>
              </div>
              {detallesJSX}
            </div>
          </InfoWindow>
        }
      </Marker>
    }) || []

    const resultMapOrigen = data_geo && Array.isArray(data_geo) && data_geo.map((item, i) => {
      let coord = { lng: null, lat: null }
      while (coordsBackup.includes(item.latitud + item.longitud)) {
        let dn = 20 + Math.floor(Math.random() * Math.floor(30))
        let de = 20 + Math.floor(Math.random() * Math.floor(40))
        let dLat = dn / R
        let dLon = de / (R * Math.cos(Math.PI * item.latitud / 180))
        item.latitud = item.latitud + dLat * 180 / Math.PI
        item.longitud = item.longitud + dLon * 180 / Math.PI
      }

      coord.lat = item.latitud
      coord.lng = item.longitud
      coordsBackup.push(item.latitud + item.longitud);

      const detallesJSX = (
        <>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Finca</strong></div>
            <div className="col-md-4">{item.finca}</div>
          </div>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Latitud(Aprox.)</strong></div>
            <div className="col-md-4">{item.latitud}</div>
          </div>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Longitud(Aprox.)</strong></div>
            <div className="col-md-4">{item.longitud}</div>
          </div>
        </>
      )

      const key = `${item.latitud}${item.longitud}`

      return <Marker
        key={key}
        onClick={() => { toggleInfoWindow(key) }}
        icon={`/media/sigat/marker-icon-blue.png`}
        position={coord}
      >
        {(showInfoWindow === key) &&
          <InfoWindow
            onCloseClick={() => { toggleInfoWindow(null) }} >
            <div className="container-fluid" style={{ maxWidth: '250px' }}>
              <div className="row">
                <div className="col-md-12"><strong>Evaluacion / Origen</strong></div>
              </div>
              {detallesJSX}
            </div>
          </InfoWindow>
        }
      </Marker>
    }) || []

    const resultMapLabores = dataMapLabores && Array.isArray(dataMapLabores) && dataMapLabores.map((item, i) => {
      let coord = { lng: null, lat: null }
      while (coordsBackup.includes(item.latitud + item.longitud)) {
        let dn = 20 + Math.floor(Math.random() * Math.floor(30))
        let de = 20 + Math.floor(Math.random() * Math.floor(40))
        let dLat = dn / R
        let dLon = de / (R * Math.cos(Math.PI * item.latitud / 180))
        item.latitud = item.latitud + dLat * 180 / Math.PI
        item.longitud = item.longitud + dLon * 180 / Math.PI
      }

      coord.lat = item.latitud
      coord.lng = item.longitud
      coordsBackup.push(item.latitud + item.longitud);
      const detallesJSX = (
        <>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Comentario</strong></div>
            <div className="col-md-4">{item.comentario}</div>
          </div>
          <div className="row" style={{}}>
            <div className="col-md-8"><strong>Cantidad de Hojas</strong></div>
            <div className="col-md-4">{item.cant_hojas_foliar}</div>
          </div>
        </>
      )

      const key = `${item.comentario} + ${item.latitud} + ${item.longitud}`

      return <Marker
        key={key}
        onClick={() => { toggleInfoWindow(key) }}
        icon={`/media/sigat/marker-icon-green.png`}
        position={coord}
      >
        {(showInfoWindow === key) &&
          <InfoWindow
            onCloseClick={() => { toggleInfoWindow(null) }} >
            <div className="container-fluid" style={{ maxWidth: '250px' }}>
              <div className="row">
                <div className="col-md-8"><strong>Labor</strong></div>
                <div className="col-md-4">{item.labor}</div>
              </div>
              {detallesJSX}
            </div>
          </InfoWindow>
        }
      </Marker>
    }) || []

    const resultMap = dataMap && Array.isArray(dataMap) && dataMap.map((item, i) => {
      const {
        data, muestras, lote
      } = item;
      let coord = { lng: null, lat: null }
      if (data.length > 0) {
        coord.lat = data[0].latitud
        coord.lng = data[0].longitud
      }
      let edadPlanta = null;
      const dataJSX = data.map((detalleMuestra) => {
        const {
          tipo,
          valor,
          edad_planta,
          variable_medicion
        } = detalleMuestra
        edadPlanta = edad_planta;
        return (<div className="row" style={{}}>
          <div className="col-md-8"><strong>{variable_medicion}</strong></div>
          <div className="col-md-4">{valor}</div>
        </div>)
      });

      return <Marker
        key={i}
        onClick={() => { toggleInfoWindow(i) }}
        icon={getMarkerColor()}
        position={coord}
      >
        {(showInfoWindow === i) &&
          <InfoWindow
            onCloseClick={() => { toggleInfoWindow(null) }} >
            <div className="container-fluid" style={{ maxWidth: '250px' }}>
              <div className="row">
                <div className="col-md-8"><strong>Edad</strong></div>
                <div className="col-md-4">{edadPlanta}</div>
                <div className="col-md-8"><strong>Lote</strong></div>
                <div className="col-md-4">{lote.nombre}</div>
              </div>
              {dataJSX}
            </div>
          </InfoWindow>
        }
      </Marker>
    }) || []

    if (check.todos) {
      return [...resultMap, ...resultMapLabores, ...resultMapDestino, ...resultMapOrigen]
    } else {
      let result = []
      if (check.labores) result = [...result, ...resultMapLabores]
      if (check.origen) result = [...result, ...resultMapOrigen]
      if (check.destino) result = [...result, ...resultMapDestino]
      if (check.muestras) result = [...result, ...resultMap]

      return result;
    }
  }, [dataMap, showInfoWindow, dataMapLabores, check]);

  if (years.length === 0) {
    return (
      <React.Fragment>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__body">No hay informaci�n para este productor</div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ReporteDiarioHeader />
      <Bar
        years={years}
        weeks={weeks}
        lotes={lotes}
        groups={groups}
        filters={filters}
        fincas={fincas}
        callbackFn={cbChangeFilter}
      />

      <div className="row" style={{}}>
        <div className="col-md-12">
          <LoadingOverlay
            active={loadIndicadores}
            spinner
            text="Cargando indicadores.."
          >
            <Indicadores
              indicadores={reportState.indicadores}
            />
          </LoadingOverlay>
        </div>
        <div className="col-md-8" style={{
          paddingTop: '30px'
        }}>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-portlet">
                  <div className="kt-portlet__body">
                    <LoadingOverlay
                      active={loadMapa}
                      spinner
                      text="Cargando resumenes.."
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <MapFilter check={check} setCheck={setCheck} />
                        </div>
                        <div className="col-md-12">
                          <Map
                            data={dataMap}
                            dataMapLabores={dataMapLabores && Array.isArray(dataMapLabores) && dataMapLabores || []}
                            dataMapOrigen={data_geo && Array.isArray(data_geo) && data_geo || []}
                            dataMapEnviada={data_geo_enviada && Array.isArray(data_geo_enviada) && data_geo_enviada || []}
                            markers={markers}
                          />
                        </div>
                      </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4" style={{
          paddingTop: '10px'
        }}>
          <LoadingOverlay
            active={loadResumenMC || loadResumenRV}
            spinner
            text="Cargando resumenes.."
          >
            <Resumen
              rv={reportState.resumenRV}
              mc={reportState.resumenRC}
            />
          </LoadingOverlay>
        </div>
        <LoadingOverlay
          active={loadFotos}
          spinner
          text="Cargando fotos.."
        >
          <Gallery
            data={reportState.fotos}
          />
        </LoadingOverlay>
      </div>
    </React.Fragment>
  );
}

Diario.propTypes = {
  name: PropTypes.string,
  age: PropTypes.number
};

export default React.memo(Diario);
