import React, { useReducer, useEffect, useState } from "react";
import clsx from "clsx";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import { DatePickerField } from './shared'
import DateHelper from '../../../../../helpers/DateHelper';
import * as constants from './constants';
import { fechaInicialLinks } from "../../../../crud/configuracionInicial.crud";
import Actions from '../shared/CreateActions';
import { TextField } from "@material-ui/core";
import { createMuiTheme, withStyles, makeStyles } from "@material-ui/core";
import { ButtonBase, Typography } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";

import {
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import reducer,  * as ducks from './reducer';
import ConfigHeader from '../ConfigHeader'

const {
  list, create, update, remove
} = fechaInicialLinks;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const New = props => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [state, dispatch] = useReducer(reducer, ducks.initialState)
  const [supSelected, setSupSelected] = useState(null);
   const configSetup = useSelector(state => state.configuration);

  const removeItem = async (id) => {
    try {
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const { client } = configSetup;
    if (client) {
      fetchData();
    }
  }, [configSetup.client]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await list(configSetup.client.technical_name);
      dispatch({type: ducks.SET_DATA, payload: response.data});
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  const semanasJSX = Object.keys(constants.semanas).map((key) => {
    return (<MenuItem value={key}>{constants.semanas[key]}</MenuItem>)
  })

  const diasJSX = Object.keys(constants.dias).map((key) => {
    return (<MenuItem value={key}>{constants.dias[key]}</MenuItem>)
  })

  return(
    <React.Fragment>
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
          <div className="kt-container  kt-container--fluid ">
            <div className="kt-subheader__main">
              <ConfigHeader
                href={'/configuration/general'}
                name={'General'}
                stage={'Nuevo'}
              />
            </div>
          </div>
        </div>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
      Nuevo
                  </div>
                </div>
                  <div className="kt-portlet__body">
          <Formik
            initialValues={{
              inicio_anio: new Date(),
              fin_anio: new Date(),
              anio_referencia: new Date(),
              dia_inicio: null,
              semana_cierre: null,
            }}
            validate={values => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              console.info(values.anio_referencia)
              setTimeout(() => {
                create(configSetup.client.technical_name,
                       {
                         inicio_anio: DateHelper.getDateFromString(values.inicio_anio, 'YYYY-MM-DD'),
                         fin_anio: DateHelper.getDateFromString(values.fin_anio, 'YYYY-MM-DD'),
                         anio_referencia: values.anio_referencia
                           && DateHelper.getDateFromString(values.anio_referencia, 'YYYY'),
                         calendario: {
                           dia_inicio: values.dia_inicio,
                           semana_cierre: values.semana_cierre,
                         }
                       })
                  .then(() => {
                    disableLoading();
                    history.push('/configuration/general/list')
                  })
                  .catch(e => {
                    console.error(e.response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Error creando');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="form-group">
                  <FormControl className={classes.formControl}>
                  <label for="anio_referencia">Año de referencia</label>
                  <DatePickerField
                    id="anio_referencia"
                    dateFormat="yyyy"
                    showYearPicker
                    name="anio_referencia"
                    value={values.anio_referencia}
                    onChange={handleChange}
                  />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl className={classes.formControl}>
                  <label for="inicio_anio">Inicio de año</label>
                  <DatePickerField
                    id="inicio_anio"
                    name="inicio_anio"
                    value={values.inicio_anio}
                    onChange={handleChange}
                  />
                  </FormControl>
                </div>

                <div className="form-group">
                  <FormControl className={classes.formControl}>
                    <label for="fin_anio">Fin de año</label>
                    <DatePickerField
                      id="fin_anio"
                      name="fin_anio"
                      value={values.fin_anio}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div className="form-group">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label">Semana de cierre</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.semana_cierre}
                          name="semana_cierre"
                          onChange={handleChange}
                        >
                          {semanasJSX}
                        </Select>
                      </FormControl>
                </div>

                <div className="form-group">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label">Día de Inicio</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.dia_inicio}
                          name="dia_inicio"
                          onChange={handleChange}
                        >
                          {diasJSX}
                        </Select>
                      </FormControl>
                </div>

                <Actions
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                  route={'/configuration/general/list'}
                  isSubmitting={isSubmitting}
                  style={loadingButtonStyle}
                />
              </form>
            )}
          </Formik>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default React.memo(New);
