import React, {
  useMemo,
  useState,
  useContext,
  useEffect,
  useReducer
} from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
import ConfigHeader from "../shared/ConfigHeader";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import parse, { attributesToProps } from "html-react-parser";

import { create } from "../../../../crud/tableau.crud";
import TableauReport from "tableau-react";

const options = {
  height: "100%",
  width: "100%",
  hideTabs: false
};

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const Header = ({ breadcrumb }) => (
  <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div className="row">
      <div className="col-md-12">
        <div className="kt-portlet">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                <ConfigHeader
                  parentHref={"/reportes"}
                  href={`/reportes/${breadcrumb}`}
                  name={"Reporte"}
                  stage={capitalize(breadcrumb)}
                />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const getFormat = (client, token, tipo) => {
  const script = document.createElement("script");
  script.src = `https://tableau2.procesosiq.com/javascripts/api/viz_v1.js?token=${token}"`;
  script.async = true;
  document.body.append(script);

  const format = `
  <div class='tableauPlaceholder' style='display: 'flex'; width: 100%; height: 100%'>
    <object class='tableauViz' width='100%' height='100%' style='display:none;'>
      <param name='host_url' value='https://tableau2.procesosiq.com/' />
      <param name='embed_code_version' value='3' /> <param name='site_root' value='' />
      <param name='name' value='${client.split("_")[2]}&#47;${tipo}' />
      <param name='tabs' value='no' />
      <param name='toolbar' value='yes' />
      <param name='showAppBanner' value='false' />
      <param name="ticket" value="${token}" />
    </object>
  </div>
  `;
  return parse(format);
};

const Reporte = ({ user, tipoReporte, breadcrumb, wbnumber = "" }) => {
  const [loadingToken, setLoadingToken] = useState(true);
  const [token, setToken] = useState("");
  const configSetup = useSelector(state => state.configuration);

  const fetchToken = async () => {
    try {
      // document.getElementsByClassName('tableauPlaceholder')[0].remove()
    } catch (err) {
      console.error(err);
    }
    let response = {};
    try {
      setLoadingToken(true);
      response = await create(configSetup.client.technical_name, {
        tableau_site: configSetup.tableauSite
      });
    } catch (err) {
      console.error(err);
    } finally {
      try {
        setToken(response.data[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingToken(false);
      }
    }
  };

  /* when the configuration changes we need to fetch a new token */
  useEffect(() => {
    fetchToken();
  }, [configSetup.client.technical_name, tipoReporte]);

  const tableau = useMemo(() => {
    if (loadingToken) {
      return <React.Fragment />;
    } else {
      if (token) {
        const productor = configSetup.client.technical_name.split("_")[2];
        let url = "";
        const query = `?:embed=y&:showVizHome=no&:host_url=https://tableau2.procesosiq.com/&:embed_code_version=3&:tabs=no&:toolbar=${
          user.username === "agripacadmin" ? "no" : "top"
        }&:showAppBanner=false&:display_spinner=no&:loadOrderID=0&:showShareOptions=false&:customViews=no`;
        if (configSetup.tableauSite) {
          url = `https://tableau2.procesosiq.com/t/${configSetup.tableauSite}/views/${productor}${wbnumber}/${tipoReporte}`;
        } else {
          url = `https://tableau2.procesosiq.com/views/${productor}${wbnumber}/${tipoReporte}`;
        }
        return (
          <TableauReport
            options={options}
            url={url}
            token={token}
            query={query}
          />
        );
      }
    }
  }, [loadingToken, token]);

  return (
    <React.Fragment>
      <Header breadcrumb={breadcrumb} />
      <div className="row" style={{}}>
        <div
          className="col-md-12"
          style={{
            paddingTop: "30px"
          }}
        >
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="kt-portlet">
                  <div className="kt-portlet__body">
                    <div
                      id="tableau-container"
                      className="row"
                      style={{ height: "80vh" }}
                    >
                      {tableau}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Reporte.propTypes = {};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(Reporte);
