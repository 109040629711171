import React from 'react'

import clsx from 'clsx'
import DeleteIcon from '@material-ui/icons/Delete'
import BackupIcon from '@material-ui/icons/Backup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GlobalFilter from './GlobalFilter'
import { lighten, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import {
  IconButton,
  Tooltip,
  Typography,
  Toolbar,
} from "@material-ui/core";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  deleteTooltip: {
    position: 'fixed',
    marginLeft: '80%'
  },
  uploadTooltip: {
    position: 'fixed',
    marginLeft: '80%'
  },
  downloadTooltip: {
    position: 'fixed',
    marginLeft: '85%'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  grow: {
    flexGrow: 1,
  },
}))

const TableToolbar = props => {
  const classes = useToolbarStyles()
  const {
    numSelected,
    addUserHandler,
    deleteUserHandler,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    uploadData,
    downloadData,
  } = props
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Muestras
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Borrar" className={classes.deleteTooltip}>
          <IconButton aria-label="borrar" onClick={deleteUserHandler}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

        <Tooltip title="Subir" className={classes.uploadTooltip}>
          <IconButton aria-label="upload" onClick={uploadData}>
            <BackupIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Descargar" className={classes.downloadTooltip}>
          <IconButton aria-label="download" onClick={downloadData}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      {/* : ( */}
      {/*   <GlobalFilter */}
      {/*     preGlobalFilteredRows={preGlobalFilteredRows} */}
      {/*     globalFilter={globalFilter} */}
      {/*     setGlobalFilter={setGlobalFilter} */}
      {/*   /> */}
      {/* )} */}
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  addUserHandler: PropTypes.func.isRequired,
  deleteUserHandler: PropTypes.func.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
}

export default TableToolbar
